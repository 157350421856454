<template>
  <div>
    <TopBar
        :title="productName"
        :leftIcon="'arrow_back'"
    />
    <LoadingIndicator v-model="loading"/>
    <div class="inner-html">
      <div v-html="HtmlResult"></div>
    </div>
    <BottomBar>
      <template v-slot:left>
        <v-avatar size="55" class="avatar">
          <img :src="avatar" alt="">&nbsp;
        </v-avatar>
        {{ name }}
        <!-- 新添加的切换技师按钮 -->
        <v-btn color="primary" small @click=switchTechnician(productId)>
          切换技师
        </v-btn>
      </template>
      <template v-slot:right>
        <div style="font-size: 20px; display: flex; align-items: center; gap: 10px">
          ฿ {{ productPrice }}
          <v-btn color="error" large @click="ProjectAppointment(productId)">
            立即预约
          </v-btn>
        </div>
      </template>
    </BottomBar>
  </div>
</template>

<script>
import TopBar from "@/components/common/navigation/TopBar.vue";
import {getHtmlContent} from "@/api/worker";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";
import ProjectAppointment from "@/components/booking/ProjectAppointment.vue";
import BottomBar from "@/components/common/navigation/BottomBar.vue";
export default {
  name: "htmlContent",
  computed: {
    orderNow() {
      return ProjectAppointment
    }
  },
  components: {BottomBar, LoadingIndicator, TopBar},
  data() {
    return {
      workerId: null,
      productId: null,
      lat: this.$route.query.latitude || null,
      lng: this.$route.query.longitude || null,
      productName: "详情",
      HtmlResult: null,
      productPrice: 0,
      avatar: null,
      name: '',
      loading: true
    };
  },
  created() {
    console.log("lat"+this.lat)
    this.lat = this.$route.query.latitude || null;
    this.lng = this.$route.query.longitude || null;
    this.productId = this.$route.query.productId;
    this.productName = this.$route.query.productName;
    this.productPrice = this.$route.query.productPrice;
    this.avatar = this.$route.query.avatar;
    this.name = this.$route.query.name;
    this.workerId = this.$route.query.workerId
    this.getHtmlContent(this.productId);
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    getHtmlContent(productId) {
      getHtmlContent(productId).then((res) => {
        this.HtmlResult = res.data.result;
        if (this.HtmlResult) {
          this.HtmlResult = this.HtmlResult.replace(/<img/g, "<img style='max-width:100%;height:auto;'");
        }
        this.loading = false
      });
    },
    ProjectAppointment(productId) {
      this.$router.push({
        name: 'ProjectAppointment',
        query: {
          productId: productId,
          workerId: this.workerId,
          workerName: this.name,
          avatar: this.avatar
        }
      });
    },
    // 新添加的切换技师方法
    switchTechnician(productId) {
      console.log('productId:' +productId)
      this.$router.push({
        name: 'SwitchTechnician',
        query: {
          productId: productId,
          latitude: this.lat,
          longitude: this.lng,
        }
      });
    },
  }}

</script>

<style scoped>
.inner-html {
  margin-top: 55px;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  color: #ffffff;
  background: #1976D2;
  padding: 2px 5px;
  display: flex;
  justify-content: space-between;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 裁剪图像而不是拉伸 */
}
</style>
