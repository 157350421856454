<template>
  <v-dialog
      style="z-index: 2000"
      v-model="dialog"
      persistent
      max-width="400px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $vuetify.lang.t("$vuetify.switchLanguage") }}</span>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
              v-for="locale in availableLanguages"
              :key="locale.code"
              @click="setLocale(locale.code)"
              :class="{ 'selected-language': locale.code === selectedLocale }"
          >
            <v-list-item-avatar v-if="locale.code === selectedLocale">
              <v-icon>mdi-check</v-icon> <!-- 可以根据需要调整图标 -->
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ locale.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
        >
          {{ $vuetify.lang.t("$vuetify.cancel") }}
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="confirmLanguageChange"
        >
          {{ $vuetify.lang.t("$vuetify.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LanguageSetting',
  data() {
    return {
      dialog: false,
      selectedLocale: 'zhHans'
    };
  },
  methods: {
    setLocale(localeCode) {
      this.selectedLocale = localeCode;
    },
    confirmLanguageChange() {
      if (this.selectedLocale === this.$vuetify.lang.current) {
        this.dialog = false;
        return; // 退出方法
      }

      this.$vuetify.lang.current = this.selectedLocale;
      localStorage.setItem('userLocale', this.selectedLocale);
      this.dialog = false;
      this.$emit('language-changed', this.selectedLocale);
      this.$root.$emit('language-changed-global', this.selectedLocale);
      window.location.reload();
    },
  }
  ,
  created() {
    // 从 localStorage 中读取保存的语言设置
    const savedLocale = localStorage.getItem('userLocale');
    if (savedLocale && this.$vuetify.lang.locales[savedLocale]) {
      this.selectedLocale = savedLocale;
      this.$vuetify.lang.current = savedLocale;
    } else {
      // 如果没有保存的语言或语言无效，使用默认语言 'zhHans'
     // this.selectedLocale = 'zhHans';
     // localStorage.setItem('userLocale', 'zhHans');
    }

    // 监听全局语言切换事件，更新语言
    this.$root.$on('language-changed-global', (newLocale) => {
      this.$vuetify.lang.current = newLocale;
    });
  },
  computed: {
    availableLanguages() {
      const { locales } = this.$vuetify.lang;
      console.log(locales); // 检查语言包内容
      return Object.keys(locales).map((lang) => {
        return {
          label: locales[lang].label,
          code: lang,
        };
      });
    },
  }
}
</script>


<style scoped>
.selected-language {
  background-color: #f0f0f0; /* 高亮颜色，可以根据需要调整 */
}

.selected-language .v-list-item-avatar {
  margin-right: 16px; /* 根据需要调整间距 */
}
</style>
