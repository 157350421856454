// src/locale/en.js
import en from 'vuetify/es5/locale/en';

export default {
    label: 'English',
    book: 'Book',
    orders: 'Orders',
    me: 'Me',
    ok: 'OK',
    cancel: 'Cancel',
    switchLanguage: 'Switch Language',
    SelectAreaACode: 'SelectAreaACode Current',
    PleaseEnterMobilePhoneNumber: 'PleaseEnterMobilePhoneNumber',
    PleaseEnterPassword: 'PleaseEnterPassword',
    Password: 'Password',
    PhoneNumber: 'PhoneNumber',
    VerificationCode: 'VerificationCode',
    secondsLater: "SecondsLater",
    //下载界面
    androidDownload: 'AndroidDownload',
    iosDownload: 'IosDownload',
    english: 'English',
    chinese: 'Chinese',
    changeToEnglish: 'Change to english',
    changeToChinese: 'Change to chinese',
    changeToSystem: 'Change to System',
    //我的页面
    commonVip: 'Member',
    FollowTechnician: 'Like Therapists',
    FollowServices: 'Like Services',
    FollowStore: 'Like Shops',
    paid: 'Paying',
    served: 'Serving',
    accepted: 'Finish',
    evaluated: 'Review',
    MyOrder: 'My orders',
    friends: 'Invite friends',
    ContactService: 'Online Service',
    DownloadOptions:'Download',
    Aftersales: 'Feedback',
    Myevaluation: 'My reviews',
    Merchant: 'Merchant admin',
    TechnicianOrders: 'Apply as Therapist',
    BrowseHistory: 'Browse history',
    ViewAll: 'View all',
    //直约列表
    TechnicianList: 'Book',
    all: 'All',
    allList: 'all',
    currentLocation: 'Current location',
    latitude: 'Latitude',
    longitude: 'Longitude',
    RefreshLocation: 'Refresh location',
    SearchLocation: 'Search location',
    doorToDoorFast: 'Speed',
    HighPopularity: 'Popularity',
    praise: 'Reviews',
    filter: 'filter',
    latestAddition:'Latest addition',
    followMore:'Follow more',
    Comment: 'Comment',
    strip: 'Strip',
    NoStaffNearby: 'No therapists nearby',
    //我的订单
    orderNumber: 'Order number',
    totalOrder: 'Total amount',
    orderTime: 'Create time',
    //技师详情
    Verified: 'Verified',
    age: 'Age',
    focusOn: 'Follow',
    Followed: 'Followed',
    Zodiac: 'Zodiac',
    constellation: 'Constellation',
    nationality: 'Nationality',
    education: 'Education',
    height: 'Height',
    distance: 'Distance',
    weight: 'Weight',
    language: 'Language',
    Introduction: 'Introduction',
    businessLicense: 'Business License',
    healthCertificate: 'Health Certificate',
    masseurCertificate: 'Masseur Certificate',
    Check: 'Check',
    serviceItems: 'Service Items',
    goodEvaluate: 'Praise',
    commentAmount: 'comments',
    minute: 'Minutes',
    sold: 'Sold',
    SecondRate: 'Person',
    ReservationNow: 'Book now',
    doorToService: 'Door-to-door service',
    photoAlbum: 'Photo',
    video: 'Video',
    //技师状态
    available: 'Available',
    inService: 'InService',
    resting: 'Resting',
    //下单
    PlaceAnOrder: 'Place An Order',
    serviceAddress: 'Address',
    hotel: 'Hotel/apartment',
    houseNumber: 'Room number',
    Name: 'Name',
    Telephone: 'Mobile',
    serviceHours: 'Time',
    servicePersonnel: 'Service personnel',
    PromoCode: 'Promo code',
    Inquire: 'Check',
    paymentMethod: 'Payments',
    DepositPaid: 'Pay',
    bookAndPay: 'Book and pay',
    hotelPlaceholder: 'Please enter the name of your hotel or apartment',
    houseNumberPlaceholder: 'Detailed address, for example, Room 101, Block A',
    pinyinSpelling: 'Full name',
    gentlemen: 'Gentlemen',
    Miss: 'Miss',
    PleaseSelectTime: 'Please select time (local time)',
    canComeEarly: 'Come early',
    callBeforeComing: 'Call before coming',
    PleaseContactMeAsSoonAsPossible: 'Please contact me as soon as possible',
    businessMessage: 'Send a message to the merchant',
    PleaseEnterDiscountCode: 'Please enter the promo code (optional)',
    Alipay: 'Alipay',
    PayPal: 'PayPal/MasterCard VISA Credit Card',
    paymentSuccessful: 'Payment successful',
    paymentFailed: 'Payment failed',
    //我的关注/评价
    myFocus: 'Following',
    myComment: 'My Comment',
    technician: 'Therapists',
    Serve: 'Serve',
    shop: 'Shop',
    delete: 'Delete',
    Revise: 'Revise',
    details: 'Details',
    //多客服
    //邀请注册
    inviteRegister: 'Invite Register',
    invitationPassword: 'invitation code',
    copy: 'Copy',
    invitationSteps: 'Invitation steps',
    ShareInvitationPostersOrInvitationLinksToFriends: 'Share invitation posters or invitation links to friends',
    FriendsDownloadTheAPPThroughPostersOrLinks: 'Friends download the APP through posters or links',
    FillInYourInvitationPasswordWhenYourFriendRegistersTheAPP: 'Fill in your invitation password when your friend registers the APP',
    AfterAFriendDownloadsTheAPPAndRegistersWithYourInvitationPasswordTaWillBecomeYourFan: 'After a friend downloads the APP and registers with your invitation password, Ta will become your fan',
    saveThePoster: 'Save the poster',
    //用户反馈
    customerFeedback: 'Customer feedback',
    selectQuestionType: 'Please select a feedback question type',
    massageFeedback: 'Massage feedback',
    APPFeedback: 'APP Feedback',
    otherFeedback: 'Feedback on other issues',
    YourQuestion: 'Your question (required)',
    ContactInformation: 'Contact information',
    submit: 'Submit',
    //设置
    setUp: 'Settings',
    personalInformation: 'Personal information',
    changePassword: 'Change Password',
    UserAgreement: 'User Agreement',
    And: 'And',
    PrivacyPolicy: 'Privacy Policy',
    cancelAccount: 'Cancel account',
    modifyLanguage: 'language',
    signOut: 'Sign out',
    logInImmediately: 'Sign in',
    multiLanguage: 'Language',
    LanguageSettingSuggestions: 'The language setting is complete, it is recommended that you re-enter the App',
    hint: 'Hint',
    OK: 'OK',
    //服务时间（当地时间）
    localTime: '(local time)',
    today: 'Today',
    tomorrow: 'Tomorrow',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    friday: 'Friday',
    Saturday: 'Saturday',
    sunday: 'Sunday',
    night: 'At night',
    expired: 'Expired',
    confirmChoice: 'Confirm the choice',
    //我的足迹
    myFootprint: 'Therapist Viewed',
    //选择城市/地址
    SelectCity: 'Select city',
    selectAddress: 'Select address',
    nearbyCities: 'Nearby cities',
    SearchCommunityNameOrBuildingName: 'Search community name/building name',
    searchCityName: 'Search city name',
    popular: 'Popular',
    CurrentCity: 'Current City',
    //筛选弹窗
    workingStatus: 'Work status',
    AvailableByAppointment: 'Available',
    TechnicianSex: 'Sex',
    sort: 'Sort',
    unlimited: 'All',
    maleTechnician: 'Male',
    femaleTechnician: 'Female',

    technicianAge: 'Age',
    minimumAge: 'Min of age',
    maximumAge: 'Max of age',
    technicianHeight: 'technicianHeight',
    minimumHeight: 'MinimumHeight',
    maximumHeight: 'MaximumHeight',
    minimumWeight: 'MinimumWeight',
    maximumWeight: 'MaximumWeight',
    technicianName: 'Name',
    PleaseEnterTheTechnicianName: 'Please enter the name of therapist',
    reset: 'Reset',
    Sure: 'Confirm',
    //登录/注册
    Login: 'Sign in',
    register: 'Sign up',
    PleaseEnterThePhoneNumber: 'Please enter the phone number',
    pleaseEnterPassword: 'Please enter password',
    SignInToAgree: 'Sign in to agree',
    and: 'And',
    VerificationCodeLogin: 'Verification code login',
    NewUserRegistration: 'New User Registration',
    pleaseEnterVerificationCode: 'Please enter verification code',
    getVerificationCode: 'Get verification code',
    passwordLogin: 'Password login',
    Reacquire: 'Reacquire',
    PleaseSetAPasswordOfMoreThanSixCharacters: 'Please set a password of more than six characters',
    pleaseEnterPasswordAgain: 'Please enter password again',
    ConfirmPassword: 'ConfirmPassword',
    InvitationCodeIsOptional: 'Invitation code is optional',
    InvitationCodeRequired: 'Invitation code, required',
    AlreadyHaveAnAccountToLogin: 'Already have an account to sign in',
    //底部数据提示
    loadMore: 'load More',
    noMoreData: 'No more data',
    loadingMoreData: 'Loading data',
    //订单详情
    orderDetails: 'Order details',
    Finish: 'Finish',
    PendingPayment: 'Pending payment',
    tobeDelivered: 'Delivering',
    TobeReceived: 'Receiving',
    WeChatPayment: 'WeChat payment',
    toPay: 'Paying',
    DeliveryMethod: 'Delivery method',
    TheAmountOfGoods: 'The amount of goods',
    MembershipVoucherDeduction: 'Membership voucher deduction',
    deliveryFee: 'Delivery fee',
    paymentAmount: 'Pay amount',
    OrderList: 'Order list',
    Receipt: 'Confirm receipt',
    AccountBalanceDeduction: 'Account balance deduction',
    payAmountTitle: 'original price amount',
    promoAmountTitle: 'Discounted price',
    reserveAmountTitle: 'Deposit paid',
    reserveAmountDescTitle: 'Deposit instructions',
    //登录/注册弹窗提示
    ToastPleaseEnterThePhoneNumber: 'Please enter the phone number',
    ToastValidNumber: 'Please enter a valid phone number',
    ToastPleaseEnterPassword: 'Please enter password',
    AtLeastSix: 'Password length must be at least 6 characters',
    AtLeastFive: 'The verification code must be at least 5 characters long',
    ToastAuthorizedLogin: 'Please log in after authorization',
    ToastAuthorizedRegister: 'Please agree to the User and Privacy Agreement and continue to register',
    ToastLoginFailed: 'Login failed',
    ToastSendCode: 'Verification code sent',
    ToastCodeFailed: 'Failed to obtain verification code',
    ToastPleaseEnterVerificationCode: 'Please enter verification code',
    ToastLoading: 'Loading...',
    ToastPasswordsDoNotMatch: 'Passwords do not match',
    ToastPleaseEnterTheInvitationCode: 'Please enter the invitation code （Optional）',
    ToastRegistrationFailed: 'Registration failed',
    CheckYourNetworkConnection: 'Registration failed, please check your network connection',
    RegistrationMeansConsent: 'Registration means consent',
    ToastCopySuccessfully: 'Copy successfully',
    loginSuccess: 'LoginSuccess',
    passwordWrong: 'PasswordWrong',
    userNotFound: 'UserNotFound',
    loginFailed: 'Login failed, please try again later',
    maxLimitReached: 'Get up to 5 messages per day',
    reached: 'Failed to send verification code, the daily limit has been reached',
    NotFoundRegister:'The user does not exist, please register first',
    FailedSendCode: 'Verification code failed to be sent, please try again later',
    FalseCode: 'Verification code error, please resend',
    //暂无数据背景提示
    noOrderYet: 'No order',
    SelectService: 'Go and choose your favorite service',
    LoginToViewYourOrder: 'Sign in to view your order',
    //选择国家弹窗
    Chinese: 'Chinese mainland',
    HongKong: 'Hong Kong',
    Thailand: 'Thailand',
    MacaoChina: 'Macao, China',
    TaiwanChina: 'Taiwan, China',
    USA: 'U.S.',
    OtherCountries: 'Other countries',
    ChooseACountry: 'Choose a country',
    orderCount: 'Order Amount',
    Partner: 'Partner',
    testFirebase: 'testFirebase',
    malaysia: 'Malaysia',
    singapore: 'Singapore',
    ThaiBank: 'ThaiBank(Submitted and contact customer service)',
    OrderTaxiFee: 'Taxi Fee',
    OrderCashTotal: 'Pay cash',
    ETA: 'ETA',
    PlsSelectTime: 'Please select time',
    Success: 'Success',
    workerName: 'Therapist Name',
    otherPayment: 'Other Payment(Submitted and contact customer service)',
    otherPaymentInfo: 'Touch N Go etc',
    AppStoreReview: 'App Store Review',
    bwh: 'BWH',
    serviceReservationTime: 'Appointment time',
    noNeedKeyCard: 'no need key Card',
    goToRoomDirectly: 'go to room directly',
    waitingAtLobby: 'waiting at lobby',
    waitingAtLift: 'waiting at the lift',
    gotIt: 'Got it',
    depositDesc: 'deposit desc',
    bookingIndexTitle: 'Booking Hotel/Home Massage in Thailand Bangkok Pattaya ChiangMai Phuket',
    ...en,
}
