<template>
  <div>
    <LoadingIndicator :value="loading"/>
    <TopBar title="注册" :leftIcon="'arrow_back'"/>
    <TopBar title="" :leftIcon="'arrow_back'"/>

    <OverflowDialog
        v-model="UserAgreementShow"
        title="用户协议"
        content="dialogContent"
        @confirm="UserAgreementShow = false"
        @close="UserAgreementShow = false"
    />

    <v-snackbar :color="color" text v-model="snackbar">
      <strong class="text-subtitle-1">{{ text }}</strong>
    </v-snackbar>

    <div class="order-content">
      <img class="logo" width="120" height="120" :src="require('../../assets/enjoyinstantlylogo.png')" alt=""/>
      <v-form ref="form" class="form-info" v-model="valid" lazy-validation>
        <VueCountryIntl
            hide-details="auto"
            v-show="showCountryIntl" :showAreaCode="true"
            :showLabelImg="true" schema="popover"
            v-model="intercode">
          <v-btn small color="orange" slot="reference">{{ $vuetify.lang.t("$vuetify.SelectAreaACode") }}:{{ intercode }}</v-btn>
        </VueCountryIntl>

        <v-text-field
            hide-details="auto"
            v-model="phoneNum" :rules="phoneRules" filled dense
            :placeholder="$vuetify.lang.t('$vuetify.PleaseEnterMobilePhoneNumber')"
            :label="$vuetify.lang.t('$vuetify.PhoneNumber')" prepend-inner-icon="call" @focus="showCountryIntl = true"
        ></v-text-field>

        <v-text-field
            hide-details="auto"
            v-model="password" :rules="passwordRules" type="password" filled dense
            :placeholder="$vuetify.lang.t('$vuetify.PleaseSetAPasswordOfMoreThanSixCharacters')" :label="$vuetify.lang.t('$vuetify.Password')" prepend-inner-icon="lock"
        ></v-text-field>

        <v-text-field
            hide-details="auto"
            v-model="passwordRepeat" :rules="passwordRepeatRules" type="password" filled dense
            :placeholder="$vuetify.lang.t('$vuetify.pleaseEnterPasswordAgain')" :label="$vuetify.lang.t('$vuetify.ConfirmPassword')" prepend-inner-icon="lock"
        ></v-text-field>

        <v-text-field
            hide-details="auto"
            v-model="inviteCode" filled dense
            :placeholder="$vuetify.lang.t('$vuetify.ToastPleaseEnterTheInvitationCode')" :label="$vuetify.lang.t('$vuetify.InvitationCodeIsOptional')" prepend-inner-icon="card_giftcard"
        ></v-text-field>

        <v-row>
          <v-col cols="7">
            <v-text-field
                hide-details="auto"
                v-model="verificationCode" :rules="verificationCodeRules" filled dense
                :placeholder="$vuetify.lang.t('$vuetify.pleaseEnterVerificationCode')" :label="$vuetify.lang.t('$vuetify.VerificationCode')" prepend-inner-icon="verified_user"
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-btn
                style="margin-top: 10px;" color="warning" text-color="white"
                @click="getRegCode" :disabled="codeCount > 0"
            >
              {{ codeCount > 0 ? `${codeCount}${$vuetify.lang.t('$vuetify.secondsLater')}` : $vuetify.lang.t('$vuetify.getVerificationCode') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-radio-group v-model="agree" style="margin: 0" row>
          <v-radio :value="true" style="margin: 0"></v-radio>
          <div class="text-subtitle-2">
            {{ $vuetify.lang.t("$vuetify.RegistrationMeansConsent") }}
            <v-btn @click="loadUserAgreement" style="padding: 0" text color="error">{{ $vuetify.lang.t("$vuetify.UserAgreement") }}</v-btn>
            {{ $vuetify.lang.t("$vuetify.And") }}
            <v-btn style="padding: 0" text color="error">{{ $vuetify.lang.t("$vuetify.PrivacyPolicy") }}</v-btn>
          </div>
        </v-radio-group>

        <v-btn
            class="login" color="warning" text-color="white" elevation="2" rounded x-large
            @click="submitForm" :disabled="!valid"
        >
          {{ $vuetify.lang.t("$vuetify.register") }}
        </v-btn>

      </v-form>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/common/navigation/TopBar.vue";
import OverflowDialog from "@/components/common/OverflowDialog.vue";
import {getRegCode, userReg} from "@/api/worker";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";

export default {
  name: "RegisterPage",
  components: {LoadingIndicator, OverflowDialog, TopBar},
  data() {
    return {
      phoneNum: '',
      password: '',
      passwordRepeat: '',
      verificationCode: '',
      inviteCode: '',
      agree: false,
      UserAgreementShow: false,
      valid: false,
      intercode: 86,
      showCountryIntl: false,
      snackbar: false,
      text: '',
      color: 'primary',
      codeCount: 0,
      loading: false,
      phoneRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.ToastPleaseEnterThePhoneNumber'),
        v => (this.intercode === 86 ? /^1[3456789]\d{9}$/.test(v) : v.length > 7) || this.$vuetify.lang.t('$vuetify.ToastValidNumber'),
      ],
      passwordRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.PleaseEnterPassword'),
        v => (v.length >= 6) || this.$vuetify.lang.t('$vuetify.AtLeastSix'),
      ],
      passwordRepeatRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.AtLeastSix'),
        v => v === this.password || this.$vuetify.lang.t('$vuetify.pleaseEnterPasswordAgain'),
      ],
      verificationCodeRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.pleaseEnterVerificationCode'),
        v => (v.length === 5) || this.$vuetify.lang.t('$vuetify.AtLeastFive'),
      ],
    };
  },
  methods: {
    loadUserAgreement() {
      // 跨域错误
      // axios.get('http://iot.leehuu.cn/go/ServiceContract?proxyId=7050280').then(res => {
      //   console.log(res)
      // })
      this.UserAgreementShow = true
    },
    getRegCode() {
      if (!this.phoneNum) {
        this.showSnackbar(this.$vuetify.lang.t("$vuetify.ToastValidNumber"), 'error');
        return;
      }
      getRegCode(this.phoneNum, this.intercode, '', 'h2').then(res => {
        console.log('getRegCode: ', res);
        if (res.data.success) {
          this.showSnackbar(this.$vuetify.lang.t("$vuetify.ToastSendCode"), 'primary');
          this.startCountdown();
        } else {
          const msg = res.data.message
          this.showSnackbar(msg, 'error');
        }
      });
    },
    startCountdown() {
      this.codeCount = 60;
      const interval = setInterval(() => {
        if (this.codeCount > 0) {
          this.codeCount--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    submitForm() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      if (!this.agree) {
        alert(this.$vuetify.lang.t("$vuetify.ToastAuthorizedRegister"));
        return;
      }
      console.log('提交表单:', {
        phoneNum: this.phoneNum,
        password: this.password,
        verificationCode: this.verificationCode,
        inviteCode: this.inviteCode,
        intercode: this.intercode,
        os: 1
      });
      this.registerUser();
    },
    registerUser() {
      this.loading = true;
      userReg(this.verificationCode.trim(), this.phoneNum.trim(), 1, this.password.trim(), this.inviteCode, this.intercode).then(res => {
        this.loading = false;
        const data = res.data;
        console.log("userReg: ", res)
        if (data.retCode === 0) {
          this.showSnackbar(this.$vuetify.lang.t("$vuetify.loginSuccess"), 'success');
          //todo:添加登录逻辑
        } else {
          this.showSnackbar(data.exception, 'error');
        }
      }).catch(err => {
        this.loading = false;
        this.showSnackbar(this.$vuetify.lang.t("$vuetify.CheckYourNetworkConnection"), 'error');
        console.error("Error during registration: ", err);
      });
    },
    showSnackbar(text, color) {
      if (color) {
        this.color = color;
      }
      this.text = text;
      this.snackbar = true;
    }
  }
}
</script>

<style scoped>
.order-content {
  margin: 20% auto 0 auto;
  padding: 10px 20px;
}

.logo {
  margin: 0 auto;
  border-radius: 10%;
  display: block;
}

.form-info {
  margin-top: 40px;
}

.v-text-field {
  margin-bottom: 10px;
}

.login {
  margin: 0 auto;
  width: 65%;
  font-size: 20px;
  display: block;
}
</style>
