import axios from 'axios';
import {urlEncode} from '@/utils/util';
import {useStore} from "@/config/store";

const instance = axios.create({});

const defaultHeaders = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "language": localStorage.getItem('userLocale') || 'zh',
    "Accept-Language": localStorage.getItem('userLocale') || 'zh'
};

const createRequestConfig = (customHeaders = {}, useToken = false) => {
    const headers = {...defaultHeaders, ...customHeaders};
    if (useToken) {
        headers["X-Access-Token"] = useStore().jwt;
    }
    return {headers};
};

const request = (method, url, data = null, useToken = false) => {
    const config = createRequestConfig({}, useToken);
    return method === 'get' ? instance.get(url, config) : instance.post(url, data, config);
};

export const serviceCategoryInfo = () =>
    request('get', `/aappii/wealth/serviceCategory/serviceCategoryInfo?proxyId=${process.env.VUE_APP_proxyId || 7050280}`);

export const workerList = (json) =>
    request('post', '/aappii/wealth/worker/workerList', json, true);

export const workerDetail = (workerId, lat, lng) =>
    request('get', `/aappii/wealth/worker/workerDetail?workerId=${workerId}&lat=${lat}&lng=${lng}`, null, true);

export const getPositionInfo = (lng, lat) =>
    request('get', `/aappii/wealth/geo/regeo?lng=${lng}&lat=${lat}`);

export const getBindCities = (pageNo, pageSize, proxyId) =>
    request('get', `/aappii/wealth/proxyCities/getBindCities?pageNo=${pageNo}&pageSize=${pageSize}&proxyId=${proxyId}`, null, true);

export const getMerchant = (params) =>
    request('get', `/aappii/wealth/merchantAdmin/getByProxyIdForApp?${urlEncode(params)}`);

export const getWorkerProductList = (workerId) =>
    request('get', `/aappii/wealth/worker/myProduct2?workerId=${workerId}`);

export const getWorkerLanguages = (workerId) =>
    request('get', `/aappii/wealth/workerLanguage/queryByWorkerId?workerId=${workerId}`);

export const getWorkerCommentList = (pageNo, pageSize, workerId) =>
    request('get', `/aappii/wealth/workerComment/workerCommentPage?pageNo=${pageNo}&pageSize=${pageSize}&workerId=${workerId}`, null, true);

export const getHtmlContent = (productId) =>
    request('get', `/aappii/wealth/product/getHtmlContent?productId=${productId}`);

export const getWorkerVideoList = (workerId) =>
    request('get', `/aappii/wealth/video/workerVideo?workerId=${workerId}`);

export const buyNowConfirmOrder = (json) =>
    request('post', `https://enjoyinstantly-aappii.lingzhuan-tech.com/wealth/order/generateBuyNowOrder`, json, true);

export const getAddrByLocation = (lat, lng) =>
    request('get', `/aappii/wealth/geo/regeo?lng=${lng}&lat=${lat}`);

export const getSearchMapByCoordinate = (lng, lat, page, pagesize) =>
    request('get', `/aappii/wealth/geo/searchMapByCoordinate?lng=${lng}&lat=${lat}&page=${page}&pagesize=${pagesize}`);

export const getSearchMapByKeywords = (city, keywords, page, pagesize, lng, lat) =>
    request('get', `/aappii//wealth/geo/searchMapByKeywords?city=${city}&keywords=${keywords}&page=${page}&pagesize=${pagesize}&lng=${lng}&lat=${lat}`);

export const getWorkerTimeSlot = (workerId) =>
    request('get', `/aappii/wealth/worker/getWorkerTimeSlot?workerId=${workerId}&timeZoneOffset=${new Date().getTimezoneOffset()}`);

export const getUserPhone = () =>
    request('get', '/aappii/wealth/order/getUserPhone', null, true);

export const PaymentInfo2 = (biz) =>
    request('get', `/aappii/wealth/paymentsetting/PaymentInfo2?proxyId=${process.env.VUE_APP_proxyId}&biz=${biz}`);

export const orderGeneration = (productId, quantity, deductionType, receiverDetailAddress, receiverLat, receiverLng, promoCode) => {
    const jsonData = {productId, quantity, deductionType, receiverDetailAddress, receiverLat, receiverLng, promoCode};
    const url = `https://enjoyinstantly-aappii.lingzhuan-tech.com/wealth/order/buyNowConfirmOrder`;
    return fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
            ...defaultHeaders,
            'X-Access-Token': useStore().jwt,
        },
        body: JSON.stringify(jsonData)
    }).then(res => res.json());
};

export const getEIIdtoken = (phone, password, os, intercode) => {
    const url = 'https://api.lingzhuan-tech.com/getidtoken';
    const data = `intercode=${intercode}&phone=${phone}&pwd=${password}&proxyId=${process.env.VUE_APP_proxyId}&os=${os}`;
    return instance.post(url, data);
};

export const getCode = (intercode, phone) => {
    const requestData = `proxyId=${process.env.VUE_APP_proxyId}&phone=${phone}&ip=&intercode=${intercode}`;
    return request('get', `https://api.lingzhuan-tech.com/getloginvericode?${requestData}`);
};

export const codeLogin = (phone, code, os, intercode) => {
    const requestData = `proxyId=${process.env.VUE_APP_proxyId}&phone=${phone}&intercode=${intercode}&code=${code}&os=${os}`;
    return request('get', `https://api.lingzhuan-tech.com/loginbyvericode?${requestData}`);
};

export const getShowUserOrderStatus = () =>
    request('get', '/aappii/wealth/order/getShowUserOrderStatus');

export const getShowUserOrder = (orderClass, pageNo, pageSize, status) =>
    request('get', `/aappii/wealth/order/myOrder?orderClass=${orderClass}&pageNo=${pageNo}&pageSize=${pageSize}&status=${status}`, null, true);

export const getOrderDetails = (orderId) =>
    request('get', `/aappii/wealth/order/detail/${orderId}`, null, true);

export const getRegCode = (phone, intercode, verKey, verCode) => {
    const requestData = `intercode=${intercode}&phone=${phone}&biz=reg&proxyId=${process.env.VUE_APP_proxyId}&verKey=${verKey}&verCode=${verCode}`;
    return request('get', `https://api.lingzhuan-tech.com/getvalicode?${requestData}`);
};

export const userReg = (vcode, phone, os, password, inviteCode, intercode) => {
    const requestData = `vcode=${vcode}&intercode=${intercode}&phone=${phone}&os=${os}&pwd=${password}&proxyId=${process.env.VUE_APP_proxyId}&inviteCode=${inviteCode}`;
    return instance.post('https://api.lingzhuan-tech.com/reg', requestData);
};

export const getContactLink = () => request('get', `/aappii/wealth/contactLink/getContactLink?proxyId=${process.env.VUE_APP_proxyId}`, null, true);
