// src/locale/ja.js
import ja from 'vuetify/es5/locale/ja';
export default {
    label: '日本語',
    book: '予約',
    orders: '注文',
    me: '私の',
    ok: 'はい',
    cancel: 'キャンセル',
    //
    SelectAreaACode: '国番号を選択 現在',
    PleaseEnterMobilePhoneNumber: '携帯電話番号を入力してください',
    PleaseEnterPassword: 'パスワードを入力してください',
    Password: 'パスワード',
    PhoneNumber: '携帯電話番号',
    VerificationCode: '認証コード',
    secondsLater: "秒後に再試行",
    androidDownload: 'Androidダウンロード',
    iosDownload: 'iOSダウンロード',
    switchLanguage: '言語を切り替える',
    english: '英語',
    chinese: '中国語',
    changeToEnglish: '英語に切り替える',
    changeToChinese: '中国語に切り替える',
    changeToSystem: 'システム言語に切り替える',
    //私のページ
    commonVip: '一般会員',
    FollowTechnician: '技術者をフォロー',
    FollowServices: 'サービスをフォロー',
    FollowStore: '店舗をフォロー',
    paid: '支払い待ち',
    served: 'サービス待ち',
    accepted: '受け入れ待ち',
    evaluated: '評価待ち',
    MyOrder: '私の注文',
    friends: '友達を招待',
    ContactService: 'オンラインサポート',
    DownloadOptions: 'ダウンロードオプション',
    Aftersales: 'フィードバック',
    Myevaluation: '私の評価',
    Merchant: '商家管理',
    TechnicianOrders: '技術者募集',
    BrowseHistory: '閲覧履歴',
    ViewAll: 'すべて表示',
    //直約リスト
    TechnicianList: '直接予約技術者',
    all: 'すべて',
    allList: 'すべて',
    currentLocation: '現在の位置',
    latitude: '緯度',
    longitude: '経度',
    RefreshLocation: '位置を更新',
    SearchLocation: '位置を検索',
    doorToDoorFast: '出張が早い',
    HighPopularity: '人気が高い',
    praise: '好評',
    latestAddition: '最新加入',
    followMore: '多くをフォロー',
    filter: 'フィルター',
    Comment: '評価',
    strip: '件',
    NoStaffNearby: '近くにスタッフはいません',
    //私の注文
    orderNumber: '注文番号',
    totalOrder: 'オンライン支払い',
    orderTime: '注文時間',
    //技術者詳細
    Verified: '本人確認済み',
    age: '年齢',
    focusOn: 'フォロー中',
    Followed: 'フォロー済み',
    Zodiac: '干支',
    constellation: '星座',
    nationality: '国籍',
    education: '学歴',
    height: '身長',
    distance: '距離',
    weight: '体重',
    language: '言語',
    Introduction: '紹介',
    businessLicense: '営業許可証',
    healthCertificate: '健康証明書',
    masseurCertificate: 'マッサージ師証',
    Check: '確認',
    serviceItems: 'サービス項目',
    goodEvaluate: '高評価',
    commentAmount: '評価数',
    minute: '分',
    sold: '販売済み',
    SecondRate: '回',
    ReservationNow: '今すぐ予約',
    doorToService: '出張サービス',
    photoAlbum: 'アルバム',
    video: '動画',
    //技術者状態
    available: '予約可能',
    inService: 'サービス中',
    resting: '休憩中',
    //注文する
    PlaceAnOrder: '注文する',
    serviceAddress: 'サービスアドレス',
    hotel: 'ホテル/アパート',
    houseNumber: '部屋/番号',
    Name: '名前',
    Telephone: '電話',
    serviceHours: 'サービス時間',
    servicePersonnel: 'サービス担当者',
    PromoCode: 'プロモーションコード',
    Inquire: '問い合わせ',
    paymentMethod: '支払い方法',
    DepositPaid: '支払待ちデポジット',
    bookAndPay: '予約して支払う',
    hotelPlaceholder: '滞在しているホテルまたはアパートの名前を入力してください',
    houseNumberPlaceholder: '詳細な住所（例：A棟101号室）',
    pinyinSpelling: 'ピンイン全綴り',
    gentlemen: '紳士',
    Miss: '女士',
    PleaseSelectTime: 'サービス時間を選択してください（現地時間）',
    canComeEarly: '早めに来ることができます',
    callBeforeComing: '来る前に電話してください',
    PleaseContactMeAsSoonAsPossible: 'できるだけ早く連絡してください',
    businessMessage: '商店にメッセージを伝える',
    PleaseEnterDiscountCode: '割引コードを入力してください（任意）',
    Alipay: 'Alipay支払い',
    PayPal: 'MasterCard、VISAなどのクレジットカード/PayPal',
    paymentSuccessful: '支払い成功',
    paymentFailed: '支払い失敗',
//私のフォロー/評価
    myFocus: '私のフォロー',
    myComment: '私の評価',
    technician: '技術者',
    Serve: 'サービス',
    shop: '店舗',
    delete: '削除',
    Revise: '修正',
    details: '詳細',
//多カスタマー
//招待登録
    inviteRegister: '招待登録',
    invitationPassword: '招待パスワード',
    copy: 'コピー',
    invitationSteps: '招待手順',
    ShareInvitationPostersOrInvitationLinksToFriends: '招待ポスターまたは招待リンクを友達に共有する',
    FriendsDownloadTheAPPThroughPostersOrLinks: '友達がポスターやリンクからアプリをダウンロードする',
    FillInYourInvitationPasswordWhenYourFriendRegistersTheAPP: '友達がアプリを登録する際にあなたの招待パスワードを入力する',
    AfterAFriendDownloadsTheAPPAndRegistersWithYourInvitationPasswordTaWillBecomeYourFan: '友達がアプリをダウンロードし、あなたの招待パスワードで登録すると、その友達はあなたのファンになります',
    saveThePoster: '専用ポスターをアルバムに保存する',
//ユーザーフィードバック
    customerFeedback: 'ユーザーフィードバック',
    selectQuestionType: 'フィードバックの問題タイプを選択してください',
    massageFeedback: '出張マッサージサービスのフィードバック',
    APPFeedback: 'アプリフィードバック',
    otherFeedback: 'その他の問題フィードバック',
    YourQuestion: 'あなたの質問（必須）',
    ContactInformation: '連絡先情報',
    submit: '提出',
//設定
    setUp: '設定',
    personalInformation: '個人情報',
    changePassword: 'パスワード変更',
    UserAgreement: 'ユーザー契約',
    And: 'と',
    PrivacyPolicy: 'プライバシーポリシー',
    cancelAccount: 'アカウントをキャンセル',
    modifyLanguage: '言語を変更',
    signOut: 'ログアウト',
    logInImmediately: '今すぐログイン',
    multiLanguage: '多言語',
    LanguageSettingSuggestions: '言語設定が完了しました。アプリに再度入ることをお勧めします',
    hint: 'ヒント',
    OK: 'はい',
//サービス時間（現地時間）
    localTime: '（現地時間）',
    today: '今日',
    tomorrow: '明日',
    Monday: '月曜日',
    Tuesday: '火曜日',
    Wednesday: '水曜日',
    Thursday: '木曜日',
    friday: '金曜日',
    Saturday: '土曜日',
    sunday: '日曜日',
    night: '夜間',
    expired: '予約終了',
    confirmChoice: '選択を確認',
    myFootprint: '私の足跡',
    //都市/住所の選択
    SelectCity: '都市を選択',
    selectAddress: '住所を選択',
    nearbyCities: '近隣の都市',
    SearchCommunityNameOrBuildingName: 'コミュニティ名/ビル名を検索',
    searchCityName: '都市名を検索',
    popular: '人気',
    CurrentCity: '現在の都市',
//フィルターポップアップ
    workingStatus: '勤務状態',
    AvailableByAppointment: '予約可能',
    TechnicianSex: '技師の性別',
    sort: '分類',
    unlimited: '制限なし',
    maleTechnician: '男性技師',
    femaleTechnician: '女性技師',
    technicianAge: '技師の年齢',
    minimumAge: '最小年齢',
    maximumAge: '最大年齢',
    technicianHeight: '技師の身長',
    minimumHeight: '最小身長',
    maximumHeight: '最大身長',
    minimumWeight: '最大重量',
    maximumWeight: '最小重量',
    technicianName: '技師の名前',
    PleaseEnterTheTechnicianName: '技師の名前を入力してください',
    reset: 'リセット',
    Sure: '確定',
//ログイン/登録
    Login: 'ログイン',
    register: '登録',
    PleaseEnterThePhoneNumber: '電話番号を入力してください',
    pleaseEnterPassword: 'パスワードを入力してください',
    SignInToAgree: 'ログインすることで同意したことになります',
    and: 'と',
    VerificationCodeLogin: '確認コードでログイン',
    NewUserRegistration: '新規ユーザー登録',
    pleaseEnterVerificationCode: '確認コードを入力してください',
    getVerificationCode: '確認コードを取得',
    passwordLogin: 'パスワードでログイン',
    Reacquire: '再取得',
    PleaseSetAPasswordOfMoreThanSixCharacters: '6文字以上のパスワードを設定してください',
    pleaseEnterPasswordAgain: 'パスワードを再入力してください',
    ConfirmPassword: 'パスワードの確認',
    InvitationCodeIsOptional: '招待コード（任意）',
    InvitationCodeRequired: '招待コード（必須）',
    AlreadyHaveAnAccountToLogin: '既にアカウントをお持ちの場合はログイン',
//フッターデータのヒント
    loadMore: 'もっと読み込む',
    noMoreData: 'これ以上のデータはありません',
    loadingMoreData: 'さらにデータを読み込んでいます',
    //注文詳細
    orderDetails: '注文詳細',
    Finish: '完了',
    PendingPayment: '未払い',
    tobeDelivered: '発送待ち',
    TobeReceived: '受け取り待ち',
    WeChatPayment: 'WeChatペイメント',
    toPay: '支払う',
    DeliveryMethod: '配送方法',
    TheAmountOfGoods: '支払い金額',
    MembershipVoucherDeduction: '会員クーポンの控除',
    promoAmountTitle: '割引済み',
    deliveryFee: '配送費',
    payAmountTitle: '元の価格',
    reserveAmountTitle: '支払済みのデポジット',
    reserveAmountDescTitle: 'デポジットの説明',
    OrderList: '注文リスト',
    Receipt: '受け取り確認',
    AccountBalanceDeduction: '口座残高の控除',
//ログイン/登録ポップアップメッセージ
    ToastPleaseEnterThePhoneNumber: '電話番号を入力してください',
    ToastValidNumber: '有効な電話番号を入力してください',
    ToastPleaseEnterPassword: 'パスワードを入力してください',
    AtLeastSix: 'パスワードは6文字以上である必要があります',
    AtLeastFive: '確認コードは5文字以上である必要があります',
    ToastAuthorizedLogin: 'ユーザーとプライバシーの合意に同意してからログインしてください',
    ToastAuthorizedRegister: 'ユーザーとプライバシーの合意に同意して、登録を続行してください',
    ToastLoginFailed: 'ログイン失敗',
    ToastSendCode: '確認コードが送信されました',
    ToastCodeFailed: '確認コードの取得に失敗しました',
    ToastPleaseEnterVerificationCode: '確認コードを入力してください',
    ToastLoading: '読み込み中...',
    ToastPasswordsDoNotMatch: 'パスワードが一致しません',
    ToastPleaseEnterTheInvitationCode: '招待コードを入力してください（任意）',
    ToastRegistrationFailed: '登録失敗',
    CheckYourNetworkConnection: '登録に失敗しました。ネットワーク接続を確認してください',
    RegistrationMeansConsent: '登録することで同意したことになります',
    ToastCopySuccessfully: 'コピー成功',
    loginSuccess: 'ログイン成功',
    loginFailed: 'ログイン失敗。しばらくしてから再試行してください',
    passwordWrong: 'パスワードが間違っています',
    userNotFound: 'ユーザーが見つかりません',
    NotFoundRegister: 'ユーザーが見つかりません。先に登録してください',
    maxLimitReached: '1日に最大5件の情報を取得できます',
    reached: '確認コードの送信に失敗しました。日次制限に達しました',
    FailedSendCode: '確認コードの送信に失敗しました。しばらくしてから再試行してください',
    FalseCode: '確認コードが間違っています。再送信してください',
//データがないときの背景メッセージ
    noOrderYet: 'まだ注文はありません',
    SelectService: 'お気に入りのサービスを選んでください',
    LoginToViewYourOrder: 'ログインすると注文が確認できます',
//国を選択するポップアップ
    Chinese: '中国本土',
    HongKong: '中国香港',
    Thailand: 'タイ',
    MacaoChina: '中国マカオ',
    TaiwanChina: '中国台湾',
    USA: 'アメリカUSA',
    OtherCountries: 'その他の国',
    ChooseACountry: '国を選択',
    orderCount: 'サービス済みの注文',
    Partner: 'パートナー',
    testFirebase: 'testFirebase',
    malaysia: 'マレーシア',
    singapore: 'シンガポール',
    ThaiBank: 'タイの銀行カード（送信後にカスタマーサポートに支払いコードを求めてください）',
    OrderTaxiFee: 'タクシー料金',
    OrderCashTotal: '合計現金支払い',
    ETA: '推定到着時間',
    PlsSelectTime: '時間を選択してください',
    Success: '成功',
    workerName: '技師',
    otherPayment: 'その他の支払い方法（送信後にカスタマーサポートに連絡してください）',
    otherPaymentInfo: 'Touch N Goなどに対応',
    AppStoreReview: 'AppStore評価',
    bwh: '三次元サイズ',
    serviceReservationTime: '予約時間',
    noNeedKeyCard: 'エレベーターはカード不要',
    goToRoomDirectly: 'フロントが部屋まで案内します',
    waitingAtLobby: 'ロビーで待機',
    waitingAtLift: 'エレベーター口で待機',
    gotIt: 'わかりました',
    depositDesc: 'デポジット支払いの説明',
    bookingIndexTitle: 'ホテルへの出張マッサージ予約 バンコク パタヤ チェンマイ プーケット',
    ...ja
}