<template>
  <div>
    <BottomNavigation/>
    <LoadingIndicator v-model="loading"/>
    <GoogleMaps :model-value="showMapDialog" @locationSelected="handleLocationSelected"
                @closeDialog="showMapDialog = false"/>
    <CustomerService ref="customerService"/>
    <LanguageSetting ref="languageSetting"/>

    <FilterDrawer
        v-model="drawerShow"
        @apply-filters="handleApplyFilters"
    />

    <img :src="require('@/assets/pinkCustomerService@2x.png')" width="50" @click="showCustomerService"
         class="customerService" alt="">
    <div class="top-bar">
      <div class="top-info">
        <span class="left-item">
          <v-btn @click="locationShow = !locationShow" text>
            <v-icon small dark>location_on</v-icon>
            {{ this.province }}
          </v-btn>
        </span>
        <strong class="center-item text-h6">
          {{ $vuetify.lang.t("$vuetify.TechnicianList") }}
        </strong>
        <span style="position: absolute;right: 8px">
          <v-btn small text @click="this.openLanguageDialog">
            <v-icon small>translate</v-icon>&nbsp;
            <span>{{ $vuetify.lang.t("$vuetify.modifyLanguage") }}</span>
          </v-btn>
        </span>
      </div>
      <div style="display: flex;flex-direction: row;justify-content: flex-start;gap: 15px;margin-top: 10px">
        <div class="item" v-for="(item,index) in categoryList" :key="index"
             :class="{ 'selected': item.id === serviceCategoryId }" @click="handleCategory(item.id)">
          <img width="25" :src="item.selectedImg" alt="">
          <div class="text-subtitle-2">{{ item.title }}</div>
        </div>
      </div>
      <div style="margin-top: 10px">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small style="padding: 0 5px"
                   text color="black" dark v-bind="attrs" v-on="on">
              <v-icon small>public</v-icon>&nbsp;
              {{ selectedCity }}&nbsp;
              <v-icon small>expand_more</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="handleSelectCity(item)" v-for="(item, index) in bindCities" :key="index">
              <v-list-item-title>{{ item.cityName }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <span style="display: inline-flex;gap: 5px">
         <v-chip
             v-for="(item, index) in sortTypes"
             :key="index"
             small
             outlined
             :color="sortType === item.sortValue ? 'error' : ''"
             @click="handleSortType(item)"
         >
          {{ item.label }}
        </v-chip>
          <v-chip small @click="drawerShow = true" style="margin: 0" label>
          <v-icon left>filter_list</v-icon>
          {{ $vuetify.lang.t("$vuetify.filter") }}
        </v-chip>
        </span>
      </div>
    </div>

    <div class="waterfall-container" @scroll="handleScroll">
      <div class="top" style="margin-top: 160px">
        <div style="background-color: white">
          <v-banner v-if="locationShow" two-line>
            <v-avatar slot="icon" size="40">
              <v-icon>location_on</v-icon>
            </v-avatar>
            {{ $vuetify.lang.t("$vuetify.currentLocation") }}：{{ this.formatted_address }} {{ this.formatted_address2 }}<br>
            <div v-if="hotelName">{{ $vuetify.lang.t("$vuetify.hotel") }}: {{ hotelName }}</div>
            <div v-if="latitude && longitude">{{ $vuetify.lang.t("$vuetify.latitude") }}: {{ latitude }}, {{ $vuetify.lang.t("$vuetify.longitude") }}: {{ longitude }}</div>
            <template v-slot:actions>
              <v-btn @click="getCurrent" depressed color="primary">{{ $vuetify.lang.t("$vuetify.RefreshLocation") }}</v-btn>
              <v-btn @click="showMapDialog = true" depressed color="primary">{{ $vuetify.lang.t("$vuetify.SearchLocation") }}</v-btn>
            </template>
          </v-banner>
        </div>
      </div>
      <div style="margin-top: 170px"></div>

      <div v-for="(item, index) in items" :key="index" class="waterfall-item">
        <v-card
            :loading="loading"
            class="mx-auto my-12"
            max-width="380"
            @click="openDetail(item.id)"
        >
          <div style="text-align: center;height: 300px;max-width: 100%;">
            <img
                class="responsive-img"
                :src="item.avatarUrl"
            />
          </div>


          <v-card-title>
            <span>{{ item.name }} <span v-if="item.age">{{ $vuetify.lang.t("$vuetify.age") }} {{ item.age }} </span> <span v-if="item.height>50">{{ $vuetify.lang.t("$vuetify.height") }} {{ item.height }}cm </span> <span v-if="item.weight>10">{{ $vuetify.lang.t("$vuetify.weight") }} {{ item.weight }}kg </span></span>
            <img src="../../assets/photosicon.jpg" alt="Photos Icon" @click="openDetail(item.id)">
            <img v-if="item.isHaveVideo" src="../../assets/isHaveVideo.png" alt="Video Icon">
          </v-card-title>

          <v-card-text>
            <v-row
                align="center"
                class="mx-0"
            >
              <v-rating
                  :value="item.comprehensiveScore"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="14"
              ></v-rating>&nbsp;
              {{ item.comprehensiveScore }}
              <span  v-if="item.commentCount > 0">
                 ({{ item.commentCount }})
              </span>

              <span style="margin-left: 8px;" v-if="item.followedCount > 0">
                <!-- 当 followedCount 大于 0 时显示的内容 -->
                 {{ $vuetify.lang.t("$vuetify.focusOn") }}({{item.followedCount}})
              </span>

            </v-row>
            <div class="my-4 text-subtitle-1" style="display: flex;flex-direction: column">
              <div class="" style="margin-bottom: 0">
                 {{ $vuetify.lang.t("$vuetify.distance") }}：{{ item.distance / 1000 }}km
                <a @click.stop="openGoogleMapsRoute(item.receiveOrderLocation.y, item.receiveOrderLocation.x)" style="cursor: pointer;">
                  <img src="../../assets/google-maps.png" alt="Google Maps" style="width: 16px; height: 16px; vertical-align: middle;">
                  <span>查看路程</span>
                </a>
              </div>
              <span style="margin-left: 0; transform: translateX(-10px);">
              <v-chip
                  class="ma-2"
                  :color="getChipColor(item.workStatusDesc)"
                  style="width: auto"
              >
              {{ item.workStatusDesc }}
            </v-chip>
            </span>

              <!--              状态：{{ item.workStatusDesc }}-->
              <div>{{ item.desc }}</div>
            </div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions>
            <v-btn
                color="blue darken-3"
                text
                @click="reserve"
            >
              Reserve
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div class="button-container" v-if="hasMoreData">
        <v-btn rounded large color="warning" @click="loadMore">
          {{ $vuetify.lang.t("$vuetify.loadMore") }}
        </v-btn>
      </div>

    </div>
  </div>
</template>

<script>
import {serviceCategoryInfo, workerList, getPositionInfo, getMerchant, getBindCities} from '@/api/worker'
import technicianStatus from '../../data/technicianStatus.json';
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";
import BottomNavigation from "@/components/common/navigation/BottomNavigation.vue";
import GoogleMaps from "@/components/common/map/GoogleMaps.vue";
import CustomerService from "@/components/common/CustomerService.vue";
import FilterDrawer from "@/components/booking/FilterDrawer.vue";
import LanguageSetting from "@/components/profile/LanguageSetting.vue";

export default {
  name: "BookingPage",
  components: {LanguageSetting, FilterDrawer, CustomerService, GoogleMaps, BottomNavigation, LoadingIndicator},
  data() {
    return {
      merchantList: [],
      lat: null,
      lng: null,
      formatted_address2: '',
      showModal: false,
      mapCenter: {lat: 13.7477981, lng: 100.577165},
      markers: [],
      autocomplete: null,
      showGallery: false,
      images: [],
      index: 1,
      selectedStatus: -1,
      selectedMerchantId: -1,
      statusOptions: technicianStatus,
      isIpLocation: false,
      query: '',
      systemLocale: '',
      applicationLocale: '',
      items: [],
      categoryList: [],
      serviceCategoryId: '',
      bindCities: {},
      selectedCity: this.$vuetify.lang.t('$vuetify.all'),
      selectedCityId: '',
      proxyId: process.env.VUE_APP_proxyId || 7050280,
      pageNum: 1,
      pageSize: 10,
      sortType: 0,
      sortTypes: [
        {label: this.$vuetify.lang.t('$vuetify.doorToDoorFast'), sortValue: 0},
        {label: this.$vuetify.lang.t('$vuetify.HighPopularity'), sortValue: 1},
        {label: this.$vuetify.lang.t('$vuetify.praise'), sortValue: 2},
        {label: this.$vuetify.lang.t('$vuetify.latestAddition'), sortValue: 3},
        {label: this.$vuetify.lang.t('$vuetify.followMore'), sortValue: 4},
      ],
      loading: false,
      totalPages: 0,
      locationShow: false,
      selectedLanguage: '',
      languageList: [
        {name: this.$vuetify.lang.t('$vuetify.allList'), code: ''},
        {name: '中文', code: 'zh'},
        {name: 'English', code: 'en'},
        {name: 'ไทย', code: 'th'}
      ],
      showMapDialog: false,
      province: '',
      drawerShow: false,
      filters:{}
    }
  },
  computed: {
    latitude() {
      return this.getQueryParam('latitude') || this.$route.params.latitude;
    },
    longitude() {
      return this.getQueryParam('longitude') || this.$route.params.longitude;
    },
    formatted_address() {
      return this.getQueryParam('formatted_address') || this.$route.params.formatted_address;
    },
    hotelName() {
      return this.getQueryParam('hotel') || this.$route.params.hotel;
    },
    hasMoreData() {
      return this.pageNum < this.totalPages;
    }
  },
  watch: {
    '$route.params.latitude': 'fresh',
    '$route.params.longitude': 'fresh'
  },
  created() {
    if (this.latitude && this.longitude) {
      this.isIpLocation = false;
      serviceCategoryInfo()
          .then(response => {
            // 请求成功，更新数据
            //this.items = response.data;
            console.log('-----serviceCategoryInfo-----: ', response.data.result);
            this.categoryList = response.data.result
            console.log("-----categoryList-----: ", this.categoryList)
            this.serviceCategoryId = response.data.result[0].id;
            this.pageNum = 1;
            this.refreshData(this.latitude, this.longitude, this.isIpLocation);
          })
          .catch(error => {
            // 请求失败，处理错误
            console.error('请求数据失败:', error);
          });
      this.getPositionInfo(this.latitude, this.longitude);
    } else {
      console.log('this.latitude 是空的')
      // 在组件创建时发送网络请求
      this.getCurrent();
    }
    this.getMerchant();
  },
  mounted() {
    this.getServiceCategoryInfo()
    this.getBindCities()
    // 添加滚动事件监听器到 window 对象上
    window.addEventListener('scroll', this.handleScroll);
    document.title =  this.$vuetify.lang.t("$vuetify.bookingIndexTitle")
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener('scroll', this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    openGoogleMapsRoute(destLat, destLng) {
      const url = `https://www.google.com/maps/dir/?api=1&origin=${destLat},${destLng}&destination=${this.lat},${this.lng}&travelmode=driving`;
      window.open(url, "_blank");
    },
    getMerchant() {
      getMerchant({proxyId: this.proxyId}).then((res => {
        if (res.data.code === 0 || res.data.code === 200) {
          let merList = [];
          merList.push({
            "merchantId": -1,
            "name": "全部"
          })
          // 假设 res.data.result 是一个数组
          if (Array.isArray(res.data.result)) {
            merList.push(...res.data.result);
          } else {
            // 如果 result 不是数组，直接添加
            merList.push(res.data.result);
          }
          this.merchantList = merList;
        }
      }));
    },
    getCurrent() {
      this.loading = true;
      this.items = []
      this.getLocation((position) => {
        if (position !== null) {
          this.isIpLocation = false;
          console.log('position')
          console.log(position)
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        } else {
          this.isIpLocation = true;
          this.loading = false;
          alert('获取当前位置失败，可以在搜索框搜素地址')
        }
        serviceCategoryInfo()
            .then(response => {
              // 请求成功，更新数据
              //this.items = response.data;
              console.log("serviceCategoryInfo:  " + response.data.result);
              this.serviceCategoryId = response.data.result[0].id;
              this.pageNum = 1;
              this.refreshData(position.coords.latitude, position.coords.longitude, this.isIpLocation,this.filters);
            })
            .catch(error => {
              // 请求失败，处理错误
              this.loading = false;
              console.error('请求数据失败:', error);
            });
        if (this.google !== null) {
          console.log('this.google !== null')
        } else {
          console.log('this.google == null')
        }
        if (position.coords.latitude && position.coords.longitude) {
          this.getPositionInfo(position.coords.latitude, position.coords.longitude);
        } else {
          console.log('this.latitude && this.longitude 是 false ')
        }

      })
    },

    getLocation(callback) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            position => {
              // this.latitude = position.coords.latitude;
              // this.longitude = position.coords.longitude;
              callback(position)
            },
            error => {
              console.error("获取位置失败:", error);
              callback(null)
            }
        );
      } else {
        console.error("浏览器不支持地理位置功能。");
        callback(null)
      }
    },
    handleScroll(event) {
      if (this.$route.name !== 'home') {
        return;
      }

      if (!this.loading && this.pageNum <= this.totalPages) {
        const {scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth} = event.target;
        const isBottomReached = scrollHeight - (clientHeight + scrollTop) <= 10; // 10是一个阈值，可以根据需要调整
        const isRightReached = scrollWidth - (clientWidth + scrollLeft) <= 10;
        if (isBottomReached || isRightReached) {
          this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation,this.filters);
        }
      }
    },
    loadMore() {
      this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation,this.filters);
    },
    getQueryParam(param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    },
    refreshData(lat, lng, isIpLocation, filters = {}) {
      if (lat === undefined || lng === undefined) {
        console.error('Latitude or longitude is undefined');
        return;
      }
      this.loading = true;
      let json = {
        proxyId: process.env.VUE_APP_proxyId || 7050280,
        serviceCategoryId: filters.serviceCategoryId || this.serviceCategoryId,
        pageNum: this.pageNum,
        pageSize: 10,
        sortType: this.sortType,
        isIpLocation: isIpLocation,
        lat: lat,
        lng: lng,
        // workStatus: this.selectedStatus,
        rememberScroll: 0,
        selectedMerchantId: filters.selectedMerchantId || this.selectedMerchantId,
        languageCode: filters.languageCode || this.selectedLanguage,
        cityId: this.selectedCityId,
        workStatus: filters.workStatus || this.selectedStatus,
        gender: filters.gender,
        startAge: filters.startAge,
        endAge: filters.endAge,
        startHt: filters.startHt,
        endHt: filters.endHt,
        workerName: filters.workerName,
      }
      console.log('refreshData json', json);
      workerList(json).then(res => {
        console.log(res.data.result);
        if (this.pageNum === 1) {
          this.items = res.data.result.records;
        } else {
          this.items = this.items.concat(res.data.result.records);
        }
        this.totalPages = res.data.result.pages;
        this.loading = false;
        this.pageNum = this.pageNum + 1;
      }).catch(error => {
        // 请求失败，处理错误
        this.loading = false;
        console.error('请求数据失败:', error);
      });
    },
    statusChange() {
      this.pageNum = 1;
      this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation);
      // this.refreshData(this.lat, this.lng, this.isIpLocation);
    },
    merchantChange() {
      this.pageNum = 1;
      this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation);
      // this.refreshData(this.lat, this.lng, this.isIpLocation);
    },
    handleLanguageChange() {
      this.pageNum = 1;
      this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation);
    },
    getStatusClass(status) {
      console.log('item.status:' + status)
      return {
        'status-active': status === 1,
        'status-rest': status === 2,
        'status-in-service': status === 3
      };
    },
    openDetail(workerId) {
      console.log('openGallery')
      this.$router.push({
        name: 'Detail',
        query: {
          id: workerId,
          latitude: this.lat,
          longitude: this.lng
        }
      })
    },
    closeGallery() {
      this.index = null
      this.showGallery = false;
    },
    getAddress(lat, lng) {
      console.log('getAddress:')
      console.log('lat:' + lat)
      console.log('lng:' + lng)
      const geocoder = new this.google.maps.Geocoder();
      lat = parseFloat(lat);
      lng = parseFloat(lng);
      const latlng = {lat, lng};
      geocoder.geocode({location: latlng}, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            console.log('results[0]:');
            console.log(results[0]);
            this.formatted_address = results[0].formatted_address;
            this.infoWindowOpened = true;
            console.log(this.formatted_address)
          } else {
            console.log('No results found');
          }
        } else {
          console.log('Geocoder failed due to: ' + status);
        }
      });
    },
    goGoogleMaps() {
      console.log('goGoogleMaps')
      history.replaceState(null, '', '/');
      console.log(this.latitude || this.lat)
      console.log(this.longitude || this.lng)
      this.$router.push({
        name: 'googleMaps',
        params: {lat: this.latitude || this.lat, lng: this.longitude || this.lng}
      });
    },
    getPositionInfo(lat, lng) {
      getPositionInfo(lng, lat).then((res) => {
        console.log('getPositionInfo:' + JSON.stringify(res))
        if (res.data.code === 200 && res.data.result != null && res.data.result != undefined) {
          console.log('res.data.code === 200')
          this.province = res.data.result.province;
          this.formatted_address2 = res.data.result.formatted_address
        }
      })
    },
    fresh() {
      this.pageNum = 1;
      this.lat = this.latitude;
      this.lng = this.longitude;
      this.refreshData(this.latitude, this.longitude, this.isIpLocation);
    },
    getChipColor(status) {
      const availableText = this.$vuetify.lang.t('available');
      const inServiceText = this.$vuetify.lang.t('inService');
      switch (status) {
        case availableText:
          return 'green';
        case inServiceText:
          return 'red';
        default:
          return '';
      }
    },
    handleLocationSelected(location) {
      console.log('Received location:', location);
      this.lat = location.latitude
      this.lng = location.longitude
      this.formatted_address2 = location.address
      this.formatted_address = location.name
      this.province = location.name.length > 4
          ? location.name.substring(0, 4) + '...'
          : location.name;
      this.items = []
      this.isIpLocation = false
      this.locationShow = false
      this.pageNum = 1;
      this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation);
      // this.refreshData(this.lat, this.lng, this.isIpLocation);    }
    },
    getServiceCategoryInfo() {
      serviceCategoryInfo()
          .then(response => {
            // 请求成功，更新数据
            //this.items = response.data;
            console.log('-----serviceCategoryInfo-----: ', response.data.result);
            this.categoryList = response.data.result
            console.log("-----categoryList-----: ", this.categoryList)
            this.serviceCategoryId = response.data.result[0].id;
            // this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation);
          })
    },
    handleCategory(id) {
      this.serviceCategoryId = id
      this.items = []
      this.pageNum = 1;
      this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation);
    },
    getBindCities() {
      getBindCities(1, 20, process.env.VUE_APP_proxyId).then(res => {
        // this.bindCities.push(res.data.result.records)
        this.bindCities = [{cityId: '', cityName: '全部'}].concat(res.data.result.records);
        console.log('bindCities: ', this.bindCities)
      })
    },
    handleSelectCity(item) {
      this.selectedCity = item.cityName
      this.selectedCityId = item.cityId
      this.items = []
      this.pageNum = 1;
      this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation);
    },
    handleSortType(item) {
      this.sortType = item.sortValue
      this.items = []
      this.pageNum = 1;
      this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation);
    },

    handleApplyFilters(filters) {
      this.filters = filters
      this.drawerShow = false
      // 处理应用筛选器的逻辑
      console.log('Applied filters:', filters);
      this.pageNum = 1; // 重置页码
      this.item = []
      this.refreshData(this.latitude || this.lat, this.longitude || this.lng, this.isIpLocation, filters);
    },
    openLanguageDialog() {
      this.$refs.languageSetting.dialog = true;
    },
    showCustomerService() {
      this.$refs.customerService.dialog = true;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.rememberScroll = document.querySelector(".waterfall-container").scrollTop;
    next();
  },
  // 缓存组件激活时调用
  activated() {
    document.querySelector('.waterfall-container').scrollTop = this.rememberScroll
  },

}
</script>


<style>

.list-item .v {
  margin-left: 5px;
}


.locale-item .text {
  flex: 1;
}


.waterfall-container {
  column-gap: 10px; /* 列间距 */
  overflow-y: auto;
  position: relative;
  padding-bottom: 35px;
}

.waterfall-item {
  break-inside: avoid; /* 防止商品分隔 */
  margin-bottom: 10px; /* 商品之间的垂直间距 */
  padding: 0 10px;
}

@media (max-width: 767px) {
  .waterfall-container {
    column-count: 1; /* 在屏幕宽度小于等于 767px 时，显示一列 */
  }
}

@media (min-width: 768px) {
  .waterfall-container {
    column-count: 2; /* 在屏幕宽度大于等于 768px 时，显示两列 */
  }
}

@media (min-width: 1024px) {
  .waterfall-container {
    column-count: 3; /* 在屏幕宽度大于等于 1024px 时，显示三列 */
  }
}


.button-container {
  display: flex;
  justify-content: center;
  transform: translateY(-10px);
}

button {
  padding: 10px 20px;
  font-size: 16px;
}


.top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 10px 8px 5px 8px;
  background: linear-gradient(to right, #c7f4e5, #a4efd7);

}

.top-info {
  display: flex;
  align-items: center;
}

.select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 0 5px 0 5px;
  max-height: 70px;
}

.customerService {
  position: fixed;
  bottom: 65px;
  right: 18px;
  z-index: 2001;
}

.left-item {
  flex: 0 0 auto;
  position: absolute;
}

.center-item {
  margin: 0 auto;
  text-align: center;
}

.item {
  background-color: white;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100px;
  align-items: center;
  gap: 5px;
}

.item.selected {
  border-color: #007bff;
  background-color: #ececec;
}
.responsive-img {
  height: 300px; /* 固定高度 */
  width: auto; /* 宽度自动调整以保持比例 */
  max-width: 100%; /* 防止图片超出容器宽度 */
  object-fit: contain; /* 适用于图片完全显示 */
}
</style>

