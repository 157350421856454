<template>
  <div>
    <LoadingIndicator :value="loading"/>
    <TopBar :title="$vuetify.lang.t('$vuetify.Login')" :leftIcon="'arrow_back'"/>

    <OverflowDialog
        v-model="UserAgreementShow"
        title="Test"
        content="dialogContent"
        @confirm="UserAgreementShow = false"
        @close="UserAgreementShow = false"
    />

    <v-snackbar :color="color" text v-model="snackbar">
      <strong class="text-subtitle-1">{{ text }}</strong>
    </v-snackbar>

    <div class="order-content">
      <img class="logo" width="120" height="120" :src="require('../../assets/enjoyinstantlylogo.png')" alt="">
      <v-form ref="form" class="form-info" v-model="valid" lazy-validation>
        <VueCountryIntl
            v-show="showCountryIntl" :showAreaCode="true"
            :showLabelImg="true" schema="popover"
            v-model="intercode">
          <v-btn small color="primary" slot="reference">{{ $vuetify.lang.t("$vuetify.SelectAreaACode") }}:{{ intercode }}</v-btn>
        </VueCountryIntl>
        <v-text-field
            v-model="phoneNum" :rules="phoneRules" filled :placeholder="$vuetify.lang.t('$vuetify.PleaseEnterMobilePhoneNumber')"
            :label="$vuetify.lang.t('$vuetify.PhoneNumber')" prepend-inner-icon="call" @focus="showCountryIntl = true"
        ></v-text-field>

        <v-text-field
            v-if="this.show === 'password'" v-model="password"
            :rules="passwordRules" type="password" filled
            :placeholder="$vuetify.lang.t('$vuetify.PleaseEnterPassword')" :label="$vuetify.lang.t('$vuetify.Password')" prepend-inner-icon="lock"
        ></v-text-field>

        <div v-else style="display: flex;flex-direction: row;gap: 10px;">
          <v-text-field
              v-model="verificationCode"
              :rules="verificationCodeRules"
              filled
              :placeholder="$vuetify.lang.t('$vuetify.pleaseEnterVerificationCode')"
              :label="$vuetify.lang.t('$vuetify.VerificationCode')"
              prepend-inner-icon="verified_user"
          ></v-text-field>
          <!--          <v-btn style="margin: 10px 0 0 0" color="success" @click="sendVerificationCode">获取验证码</v-btn>-->
          <v-btn
              style="margin: 10px 0 0 0" color="orange"
              @click="sendVerificationCode" :disabled="codeCount > 0"
          >
            {{ codeCount > 0 ? `${codeCount}${$vuetify.lang.t('$vuetify.secondsLater')}` : $vuetify.lang.t('$vuetify.getVerificationCode') }}
          </v-btn>

        </div>

        <v-radio-group v-model="agree" style="margin: 0" row>
          <v-radio :value="true" style="margin: 0"></v-radio>
          <div class="text-subtitle-2">
            {{ $vuetify.lang.t("$vuetify.SignInToAgree") }}
            <v-btn @click="loadUserAgreement" style="padding: 0" text color="error"> {{ $vuetify.lang.t("$vuetify.UserAgreement") }}</v-btn>
            {{ $vuetify.lang.t("$vuetify.And") }}
            <v-btn style="padding: 0" text color="error">{{ $vuetify.lang.t("$vuetify.PrivacyPolicy") }}</v-btn>
          </div>
        </v-radio-group>

        <v-btn
            v-if="this.show === 'password'"
            class="login" color="warning" elevation="2"
            rounded x-large @click="submitFormByPwd" :disabled="!valid"
        >
          {{ $vuetify.lang.t("$vuetify.Login") }}
        </v-btn>
        <v-btn v-else
               class="login" color="warning" elevation="2" rounded x-large
               @click="submitFormByCode" :disabled="!valid"
        >
          {{ $vuetify.lang.t("$vuetify.VerificationCodeLogin") }}
        </v-btn>

      </v-form>
      <div style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 20px">
        <v-btn v-if="this.show === 'password'"
               @click="show = 'verificationCode'"
               text color="orange" text-color="white">{{ $vuetify.lang.t("$vuetify.VerificationCodeLogin") }}
        </v-btn>
        <v-btn v-else @click="show = 'password'" text color="">{{ $vuetify.lang.t("$vuetify.passwordLogin") }}</v-btn>
        <v-btn text @click="$router.push({name:'RegisterPage'})" color="orange">{{ $vuetify.lang.t("$vuetify.NewUserRegistration") }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/common/navigation/TopBar.vue";
import OverflowDialog from "@/components/common/OverflowDialog.vue";
import axios from "axios";
import {codeLogin, getCode, getEIIdtoken} from "@/api/worker";
import {useStore} from "@/config/store";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";

export default {
  name: "LoginPage",
  components: {LoadingIndicator, OverflowDialog, TopBar},
  computed: {
    store() {
      return useStore()
    }
  },
  data() {
    return {
      phoneNum: '',
      password: '',
      agree: false,
      UserAgreementShow: false,
      PrivacyPolicyShow: false,
      valid: false,
      intercode: 86,
      verificationCode: '',
      codeCount: 0,
      phoneRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.PleaseEnterMobilePhoneNumber'),
        v => (this.intercode === 86 ? /^1[3456789]\d{9}$/.test(v) : v.length > 7) || this.$vuetify.lang.t('$vuetify.ToastValidNumber'),
      ],
      passwordRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.ToastPleaseEnterPassword'),
        v => (v.length >= 6) || this.$vuetify.lang.t('$vuetify.AtLeastSix'),
      ],
      verificationCodeRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.ToastPleaseEnterVerificationCode'),
        v => (v.length === 5) || this.$vuetify.lang.t('$vuetify.AtLeastFive'),
      ],
      showCountryIntl: false,
      snackbar: false,
      text: '',
      color: 'primary',
      show: 'password',
      loading: false
    };
  },
  methods: {
    loadUserAgreement() {
      axios.get('http://iot.leehuu.cn/go/ServiceContract?proxyId=' + process.env.VUE_APP_proxyId || 7050280).then(res => {
        console.log(res)
      })
      this.UserAgreementShow = true
    },
    submitFormByPwd() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      if (!this.agree) {
        alert(this.$vuetify.lang.t("$vuetify.ToastAuthorizedLogin"));
        return;
      }
      console.log('提交表单:', {
        phoneNum: this.phoneNum,
        password: this.password,
        intercode: this.intercode,
        os: 1
      });
      this.getEIIdtoken()
    },

    getEIIdtoken() {
      this.loading = true
      getEIIdtoken(this.phoneNum, this.password, 1, this.intercode).then(res => {
        const data = res.data
        console.log("getEIIdtoken.data: ", data)
        switch (data.retCode) {
          case 0:
            // console.log('login success')
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.loginSuccess"), 'success');
            this.handleLoginSucceed(data)
            break
          case -403:
            // console.log('password wrong')
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.passwordWrong"), 'error');
            break
          case -404:
            // console.log('user not found')
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.userNotFound"), 'error');
            break
          default:
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.loginFailed"), 'error');
            break
        }
        this.loading = false
      })
    },
    handleLoginSucceed(data) {
      this.store.loginSucceed(data.idtoken, data.jwt, this.phoneNum, this.intercode, data.userId)
      setTimeout(() => {
        this.$router.go(-1)
      }, 1000)
    },
    sendVerificationCode() {
      console.log('sendVerificationCode: ', this.phoneNum, this.intercode);
      // const isValid = this.$refs.form.validate();
      if (!this.phoneNum) {
        this.showSnackbar(this.$vuetify.lang.t("$vuetify.ToastValidNumber"), 'error');
        return;
      }
      getCode(this.intercode, this.phoneNum).then(res => {
        console.log('sendVerificationCode: ', res);
        if (res.data.retCode === 0) {
          this.showSnackbar(this.$vuetify.lang.t("$vuetify.ToastSendCode"), 'primary');
          this.startCountdown(); // 开始倒计时
        } else if (res.data.retCode === -403) {
          if (res.data.exception === this.$vuetify.lang.t("$vuetify.maxLimitReached")) {
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.loginFailed"), 'error');
          } else {
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.NotFoundRegister"), 'error');
          }
        } else {
          this.showSnackbar(this.$vuetify.lang.t("$vuetify.FailedSendCode"), 'error');
        }
      });
    },
    startCountdown() {
      this.codeCount = 60;
      const interval = setInterval(() => {
        if (this.codeCount > 0) {
          this.codeCount--;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
    submitFormByCode() {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      if (!this.agree) {
        alert(this.$vuetify.lang.t("$vuetify.ToastAuthorizedLogin"));
        return;
      }
      console.log('提交表单:', {
        phoneNum: this.phoneNum,
        verificationCode: this.verificationCode,
        intercode: this.intercode,
        os: 1
      });
      this.codeLogin()
    },
    codeLogin() {
      this.loading = true
      codeLogin(this.phoneNum, this.verificationCode, 1, this.intercode).then(res => {
        this.loading = false
        const data = res.data
        switch (data.retCode) {
          case 0:
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.loginSuccess"), 'success');
            this.handleLoginSucceed(data)
            break
          case -403:
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.FalseCode"), 'error');
            break
          case -404:
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.NotFoundRegister"), 'error');
            break
          default:
            this.showSnackbar(this.$vuetify.lang.t("$vuetify.loginFailed"), 'error');
            break
        }
      })
    },
    showSnackbar(text, color) {
      if (color) {
        this.color = color
      }
      this.text = text
      this.snackbar = true
    }
  }
}
</script>

<style scoped>
.order-content {
  margin: 20% auto 0 auto;
  padding: 10px 20px;
}

.logo {
  margin: 0 auto;
  border-radius: 10%;
  display: block;
}

.form-info {
  margin-top: 40px;
}

.login {
  margin: 0 auto;
  width: 65%;
  font-size: 20px;
  display: block;
}
</style>
