
import ko from 'vuetify/es5/locale/ko';
export default {
    label: '한국어',
    book: '예약',
    orders: '주문',
    me: '나의',
    ok: '확인',
    cancel: '취소',
    //로그인
    SelectAreaACode: '국가 코드를 선택하십시오 현재',
    PleaseEnterMobilePhoneNumber: '휴대전화 번호를 입력하십시오',
    PleaseEnterPassword: '비밀번호를 입력하십시오',
    Password: '비밀번호',
    PhoneNumber: '휴대전화 번호',
    VerificationCode: '인증 코드',
    secondsLater: "초 후에 다시 시도하십시오",
    androidDownload: '안드로이드 다운로드',
    iosDownload: '아이폰 다운로드',
    switchLanguage: '언어 변경',
    english: '영어',
    chinese: '중국어',
    changeToEnglish: '영어로 변경',
    changeToChinese: '중국어로 변경',
    changeToSystem: '시스템 언어로 변경',
    //나의 페이지
    commonVip: '일반 회원',
    FollowTechnician: '기술자 팔로우',
    FollowServices: '서비스 팔로우',
    FollowStore: '가게 팔로우',
    paid: '결제 대기 중',
    served: '서비스 대기 중',
    accepted: '수락 대기 중',
    evaluated: '평가 대기 중',
    MyOrder: '나의 주문',
    friends: '친구 초대',
    ContactService: '온라인 고객 서비스',
    DownloadOptions: '다운로드 옵션',
    Aftersales: '피드백',
    Myevaluation: '나의 평가',
    Merchant: '상점 관리',
    TechnicianOrders: '기술자 모집',
    BrowseHistory: '브라우징 기록',
    ViewAll: '모두 보기',
    //직접 예약 리스트
    TechnicianList: '직접 예약 기술자',
    all: '전체',
    allList: '전체',

    currentLocation: '현재 위치',
    latitude: '위도',
    longitude: '경도',
    RefreshLocation: '위치 새로 고침',
    SearchLocation: '위치 검색',
    doorToDoorFast: '빠른 방문 서비스',
    HighPopularity: '높은 인기',
    praise: '좋은 평가',
    latestAddition: '최신 추가',
    followMore: '많이 팔로우한',
    filter: '필터',
    Comment: '평가',
    strip: '조각',
    NoStaffNearby: '근처에 직원이 없습니다',
    //나의 주문
    orderNumber: '주문 번호',
    totalOrder: '온라인 결제',
    orderTime: '주문 시간',
    //기술자 세부사항
    Verified: '인증 완료',
    age: '나이',
    focusOn: '팔로우',
    Followed: '팔로우 됨',
    Zodiac: '띠',
    constellation: '별자리',
    nationality: '국적',
    education: '학력',
    height: '키',
    distance: '거리',
    weight: '체중',
    language: '언어',
    Introduction: '소개',
    businessLicense: '영업 허가증',
    healthCertificate: '건강 증명서',
    masseurCertificate: '마사지사 자격증',
    Check: '보기',
    serviceItems: '제공하는 서비스',
    goodEvaluate: '좋은 평가',
    commentAmount: '평가 수',
    minute: '분',
    sold: '판매 완료',
    SecondRate: '회',
    ReservationNow: '지금 예약',
    doorToService: '방문 서비스',
    photoAlbum: '사진 앨범',
    video: '비디오',
    //기술자 상태
    available: '예약 가능',
    inService: '서비스 중',
    resting: '휴식 중',
    //주문하기
    PlaceAnOrder: '주문하기',
    serviceAddress: '서비스 주소',
    hotel: '호텔/아파트',
    houseNumber: '방 번호/주소',
    Name: '이름',
    Telephone: '전화번호',
    serviceHours: '서비스 시간',
    servicePersonnel: '서비스 직원',
    PromoCode: '프로모션 코드',
    Inquire: '조회',
    paymentMethod: '결제 방법',
    DepositPaid: '선금 대기',
    bookAndPay: '예약 및 결제',
    hotelPlaceholder: '숙소 이름을 입력하십시오',
    houseNumberPlaceholder: '상세 주소, 예: A동 101호',
    pinyinSpelling: '완전 핀인',
    gentlemen: '남성',
    Miss: '여성',
    PleaseSelectTime: '서비스 시간을 선택하십시오 (현지 시간)',
    canComeEarly: '일찍 도착 가능',
    callBeforeComing: '도착 전에 전화하십시오',
    PleaseContactMeAsSoonAsPossible: '가능한 빨리 연락 주십시오',
    businessMessage: '상점에 남길 메시지',
    PleaseEnterDiscountCode: '할인 코드를 입력하십시오 (선택 사항)',
    Alipay: '알리페이 결제',
    PayPal: 'MasterCard VISA 등 신용카드/PayPal',
    paymentSuccessful: '결제 성공',
    paymentFailed: '결제 실패',
    //나의 팔로우/평가
    myFocus: '나의 팔로우',
    myComment: '나의 평가',
    technician: '기술자',
    Serve: '서비스',
    shop: '가게',
    delete: '삭제',
    Revise: '수정',
    details: '세부사항',
    //여러 고객 서비스
    //초대 등록
    inviteRegister: '초대 등록',
    invitationPassword: '초대 암호',
    copy: '복사',
    invitationSteps: '초대 단계',
    ShareInvitationPostersOrInvitationLinksToFriends: '초대 포스터 또는 초대 링크를 친구에게 공유하십시오',
    FriendsDownloadTheAPPThroughPostersOrLinks: '친구가 포스터 또는 링크를 통해 앱을 다운로드합니다',
    FillInYourInvitationPasswordWhenYourFriendRegistersTheAPP: '친구가 앱을 등록할 때 초대 암호를 입력하십시오',
    AfterAFriendDownloadsTheAPPAndRegistersWithYourInvitationPasswordTaWillBecomeYourFan: '친구가 앱을 다운로드하고 초대 암호로 등록하면 Ta가 당신의 팬이 됩니다',
    saveThePoster: '전용 포스터를 앨범에 저장하십시오',
    //사용자 피드백
    customerFeedback: '사용자 피드백',
    selectQuestionType: '피드백 문제 유형을 선택하십시오',
    massageFeedback: '방문 마사지 서비스 피드백',
    APPFeedback: '앱 피드백',
    otherFeedback: '기타 문제 피드백',
    YourQuestion: '질문 (필수)',
    ContactInformation: '연락처 정보',
    submit: '제출',
    //설정
    setUp: '설정',
    personalInformation: '개인 정보',
    changePassword: '비밀번호 변경',
    UserAgreement: '사용자 계약',
    And: '및',
    PrivacyPolicy: '개인정보 보호정책',
    cancelAccount: '계정 취소',
    modifyLanguage: '언어 수정',
    signOut: '로그아웃',
    logInImmediately: '즉시 로그인',
    multiLanguage: '다국어',
    LanguageSettingSuggestions: '언어 설정 완료 후 앱에 다시 들어가십시오',
    hint: '힌트',
    OK: '확인',
    //서비스 시간 (현지 시간)
    localTime: ' (현지 시간)',
    today: '오늘',
    tomorrow: '내일',
    Monday: '월요일',
    Tuesday: '화요일',
    Wednesday: '수요일',
    Thursday: '목요일',
    friday: '금요일',
    Saturday: '토요일',
    sunday: '일요일',
    night: '밤',
    expired: '만료됨',
    confirmChoice: '선택 확인',
    //나의 발자취
    myFootprint: '나의 발자취',
    //도시/주소 선택
    SelectCity: '도시 선택',
    selectAddress: '주소 선택',
    nearbyCities: '근처 도시',
    SearchCommunityNameOrBuildingName: '지역 또는 빌딩 이름 검색',
    searchCityName: '도시 이름 검색',
    popular: '인기',
    CurrentCity: '현재 도시',
    //필터 팝업
    workingStatus: '작업 상태',
    AvailableByAppointment: '예약 가능',
    TechnicianSex: '기술자 성별',
    sort: '정렬',
    unlimited: '제한 없음',
    maleTechnician: '남성 기술자',
    femaleTechnician: '여성 기술자',
    technicianAge: '나이',
    minimumAge: '최소 나이',
    maximumAge: '최대 나이',
    technicianHeight: '기술자 키',
    minimumHeight: '최소 키',
    maximumHeight: '최대 키',
    minimumWeight: '最大重量',
    maximumWeight: '最小重量',
    technicianName: '이름',
    PleaseEnterTheTechnicianName: '기술자의 이름을 입력해주세요',
    reset: '재설정',
    Sure: '확인',
    //登录/注册
    Login: '로그인',
    register: '가입하기',
    PleaseEnterThePhoneNumber: '전화번호를 입력해주세요',
    pleaseEnterPassword: '비밀번호를 입력해주세요',
    SignInToAgree: '동의 후 로그인',
    and: '및',
    VerificationCodeLogin: '인증 코드 로그인',
    NewUserRegistration: '신규 사용자 등록',
    pleaseEnterVerificationCode: '인증 코드를 입력해주세요',
    getVerificationCode: '인증 코드 받기',
    passwordLogin: '비밀번호 로그인',
    Reacquire: '다시 받기',
    PleaseSetAPasswordOfMoreThanSixCharacters: '6자 이상의 비밀번호를 설정해주세요',
    pleaseEnterPasswordAgain: '비밀번호를 다시 입력해주세요',
    ConfirmPassword: '비밀번호 확인',
    InvitationCodeIsOptional: '초대 코드는 선택사항',
    InvitationCodeRequired: '초대 코드 (필수)',
    AlreadyHaveAnAccountToLogin: '이미 계정이 있으신가요? 로그인하기',
    //底部数据提示
    loadMore: '더 보기',
    noMoreData: '더 이상 데이터가 없습니다',
    loadingMoreData: '데이터 로딩 중...',
    //订单详情
    orderDetails: '주문 세부 정보',
    Finish: '완료',
    PendingPayment: '결제 대기 중',
    tobeDelivered: '배송 중',
    TobeReceived: '수령 대기 중',
    WeChatPayment: 'WeChat 결제',
    toPay: '결제하기',
    DeliveryMethod: '배송 방법',
    TheAmountOfGoods: '상품 금액',
    MembershipVoucherDeduction: '회원권 할인',
    deliveryFee: '배송료',
    paymentAmount: '결제 금액',
    OrderList: '주문 목록',
    Receipt: '수령 확인',
    AccountBalanceDeduction: '계좌 잔액 차감',
    payAmountTitle: '원가 금액',
    promoAmountTitle: '할인 금액',
    reserveAmountTitle: '예치금 결제 완료',
    reserveAmountDescTitle: '예치금 설명',
    //登录/注册弹窗提示
    ToastPleaseEnterThePhoneNumber: '전화번호를 입력해주세요',
    ToastValidNumber: '유효한 전화번호를 입력해주세요',
    ToastPleaseEnterPassword: '비밀번호를 입력해주세요',
    AtLeastSix: '비밀번호는 최소 6자 이상이어야 합니다',
    AtLeastFive: '인증 코드는 최소 5자 이상이어야 합니다',
    ToastAuthorizedLogin: '사용자 동의 후 로그인해주세요',
    ToastAuthorizedRegister: '사용자 및 개인정보 보호 동의 후 가입해주세요',
    ToastLoginFailed: '로그인 실패',
    ToastSendCode: '인증 코드가 발송되었습니다',
    ToastCodeFailed: '인증 코드 발송 실패',
    ToastPleaseEnterVerificationCode: '인증 코드를 입력해주세요',
    ToastLoading: '로딩 중...',
    ToastPasswordsDoNotMatch: '비밀번호가 일치하지 않습니다',
    ToastPleaseEnterTheInvitationCode: '초대 코드를 입력해주세요 (선택사항)',
    ToastRegistrationFailed: '가입 실패',
    CheckYourNetworkConnection: '가입 실패, 네트워크 연결을 확인해주세요',
    RegistrationMeansConsent: '가입은 동의로 간주됩니다',
    ToastCopySuccessfully: '복사 성공',
    loginSuccess: '로그인 성공',
    passwordWrong: '비밀번호가 틀렸습니다',
    userNotFound: '사용자를 찾을 수 없습니다',
    loginFailed: '로그인 실패, 나중에 다시 시도해주세요',
    maxLimitReached: '하루에 최대 5개의 메시지를 받을 수 있습니다',
    reached: '인증 코드 발송 실패, 일일 제한 도달',
    NotFoundRegister:'사용자를 찾을 수 없습니다, 먼저 가입해주세요',
    FailedSendCode: '인증 코드 발송 실패, 나중에 다시 시도해주세요',
    FalseCode: '인증 코드 오류, 다시 발송해주세요',
    //暂无数据背景提示
    noOrderYet: '주문이 없습니다',
    SelectService: '원하는 서비스를 선택하세요',
    LoginToViewYourOrder: '로그인 후 주문을 확인하세요',
    //选择国家弹窗
    Chinese: '중국 본토',
    HongKong: '홍콩',
    Thailand: '태국',
    MacaoChina: '마카오, 중국',
    TaiwanChina: '타이완, 중국',
    USA: '미국',
    OtherCountries: '기타 국가',
    ChooseACountry: '국가 선택',
    orderCount: '주문 수량',
    Partner: '파트너',
    testFirebase: '테스트 파이어베이스',
    malaysia: '말레이시아',
    singapore: '싱가포르',
    ThaiBank: '태국 은행 (제출 후 고객 서비스에 연락)',
    OrderTaxiFee: '택시 요금',
    OrderCashTotal: '현금 결제 합계',
    ETA: '예상 도착 시간',
    PlsSelectTime: '시간을 선택해주세요',
    Success: '성공',
    workerName: '기술자 이름',
    otherPayment: '다른 결제 방법 (제출 후 고객 서비스에 연락)',
    otherPaymentInfo: 'Touch N Go 등 지원',
    AppStoreReview: '앱 스토어 리뷰',
    bwh: '신체 치수',
    serviceReservationTime: '예약 시간',
    noNeedKeyCard: '키카드 필요 없음',
    goToRoomDirectly: '직접 방으로 가기',
    waitingAtLobby: '로비에서 대기 중',
    waitingAtLift: '엘리베이터에서 대기 중',
    gotIt: '알겠습니다',
    depositDesc: '예치금 설명',
    bookingIndexTitle: '태국 방콕 파타야 치앙마이 푸켓 호텔/가정 마사지 예약',
    ...ko,
}