
export default {
    label: 'हिन्दी',
    book: 'बुकिंग',
    orders: 'आदेश',
    me: 'मैं',
    ok: 'ठीक है',
    cancel: 'रद्द करें',
    //लॉगिन
    SelectAreaACode: 'क्षेत्र कोड चुनें वर्तमान',
    PleaseEnterMobilePhoneNumber: 'कृपया मोबाइल फोन नंबर दर्ज करें',
    PleaseEnterPassword: 'कृपया पासवर्ड दर्ज करें',
    Password: 'पासवर्ड',
    PhoneNumber: 'मोबाइल नंबर',
    VerificationCode: 'सत्यापन कोड',
    secondsLater: "सेकंड बाद पुनः प्रयास करें",
    androidDownload: 'एंड्रॉइड डाउनलोड',
    iosDownload: 'आईओएस डाउनलोड',
    switchLanguage: 'भाषा बदलें',
    english: 'अंग्रेज़ी',
    chinese: 'चीनी',
    changeToEnglish: 'अंग्रेज़ी में बदलें',
    changeToChinese: 'चीनी में बदलें',
    changeToSystem: 'सिस्टम भाषा में बदलें',
    //मेरे पृष्ठ
    commonVip: 'सामान्य सदस्य',
    FollowTechnician: 'तकनीशक का अनुसरण करें',
    FollowServices: 'सेवाओं का अनुसरण करें',
    FollowStore: 'स्टोर का अनुसरण करें',
    paid: 'भुगतान करना है',
    served: 'सेवा में',
    accepted: 'स्वीकृत',
    evaluated: 'मूल्यांकन होना है',
    MyOrder: 'मेरा आदेश',
    friends: 'मित्रों को आमंत्रित करें',
    ContactService: 'ऑनलाइन सेवा',
    DownloadOptions: 'डाउनलोड विकल्प',
    Aftersales: 'फीडबैक',
    Myevaluation: 'मेरा मूल्यांकन',
    Merchant: 'व्यापारी प्रबंधन',
    TechnicianOrders: 'तकनीशक भर्ती',
    BrowseHistory: 'ब्राउज़ इतिहास',
    ViewAll: 'सभी देखें',
    //सीधे नियुक्ति सूची
    TechnicianList: 'सीधे नियुक्ति तकनीशक',
    all: 'सभी',
    allList: 'सभी',
    currentLocation: 'वर्तमान स्थान',
    latitude: 'अक्षांश',
    longitude: 'देशांतर',
    RefreshLocation: 'स्थान ताज़ा करें',
    SearchLocation: 'स्थान खोजें',
    doorToDoorFast: 'घर पर तेजी से',
    HighPopularity: 'उच्च लोकप्रियता',
    praise: 'अच्छी समीक्षाएं',
    latestAddition: 'हाल ही में जोड़ा गया',
    followMore: 'अधिक अनुसरण करें',
    filter: 'फिल्टर',
    Comment: 'समीक्षा',
    strip: '条',
    NoStaffNearby: 'आसपास कोई कर्मचारी नहीं है',
    //मेरे आदेश
    orderNumber: 'आदेश संख्या',
    totalOrder: 'ऑनलाइन भुगतान',
    orderTime: 'आदेश का समय',
    //तकनीशक विवरण
    Verified: 'प्रमाणित पहचान',
    age: 'उम्र',
    focusOn: 'अनुसरण करें',
    Followed: 'अनुसरण किया',
    Zodiac: 'राशि',
    constellation: 'नक्षत्र',
    nationality: 'राष्ट्रीयता',
    education: 'शिक्षा',
    height: 'ऊँचाई',
    distance: 'दूरी',
    weight: 'वजन',
    language: 'भाषा',
    Introduction: 'परिचय',
    businessLicense: 'व्यापार लाइसेंस',
    healthCertificate: 'स्वास्थ्य प्रमाणपत्र',
    masseurCertificate: 'मसाज करने वाले का प्रमाणपत्र',
    Check: 'देखें',
    serviceItems: 'सेवा की वस्तुएँ',
    goodEvaluate: 'अच्छी समीक्षा',
    commentAmount: 'समीक्षा की मात्रा',
    minute: 'मिनट',
    sold: 'बेचा गया',
    SecondRate: 'बार',
    ReservationNow: 'अभी बुक करें',
    doorToService: 'घर पर सेवा',
    photoAlbum: 'फोटो एल्बम',
    video: 'वीडियो',
    //तकनीशक स्थिति
    available: 'आरक्षित के लिए उपलब्ध',
    inService: 'सेवा में',
    resting: 'आराम कर रहा है',
    //ऑर्डर देना
    PlaceAnOrder: 'ऑर्डर देना',
    serviceAddress: 'सेवा का पता',
    hotel: 'होटल/अपार्टमेंट',
    houseNumber: 'कमरा/दरवाजा नंबर',
    Name: 'नाम',
    Telephone: 'टेलीफोन',
    serviceHours: 'सेवा के घंटे',
    servicePersonnel: 'सेवा कर्मचारी',
    PromoCode: 'प्रमोशन कोड',
    Inquire: 'जांचें',
    paymentMethod: 'भुगतान विधि',
    DepositPaid: 'अग्रिम राशि का भुगतान करना है',
    bookAndPay: 'बुक करें और भुगतान करें',
    hotelPlaceholder: 'कृपया अपने होटल या अपार्टमेंट का नाम दर्ज करें',
    houseNumberPlaceholder: 'विवरण जैसे, A ब्लॉक 101',
    pinyinSpelling: 'पिनयिन संपूर्ण',
    gentlemen: 'सर',
    Miss: 'सुश्री',
    PleaseSelectTime: 'कृपया सेवा का समय चुनें (स्थानीय समय)',
    canComeEarly: 'पहले आ सकते हैं',
    callBeforeComing: 'आने से पहले कॉल करें',
    PleaseContactMeAsSoonAsPossible: 'कृपया जल्द से जल्द मुझसे संपर्क करें',
    businessMessage: 'व्यापारी को एक संदेश भेजें',
    PleaseEnterDiscountCode: 'कृपया डिस्काउंट कोड दर्ज करें (वैकल्पिक)',
    Alipay: 'अलिपे पेमेंट',
    PayPal: 'मास्टरकार्ड वीज़ा आदि क्रेडिट कार्ड/पेपैल',
    paymentSuccessful: 'भुगतान सफल',
    paymentFailed: 'भुगतान विफल',
    //मेरी पसंद/समालोचना
    myFocus: 'मेरी पसंद',
    myComment: 'मेरी समालोचना',
    technician: 'तकनीशियन',
    Serve: 'सेवा',
    shop: 'दुकान',
    delete: 'हटाना',
    Revise: 'संशोधित करें',
    details: 'विवरण',
    //अनेक ग्राहक सेवा
    //आमंत्रण पंजीकरण
    inviteRegister: 'आमंत्रण पंजीकरण',
    invitationPassword: 'आमंत्रण पासवर्ड',
    copy: 'कॉपी करें',
    invitationSteps: 'आमंत्रण के चरण',
    ShareInvitationPostersOrInvitationLinksToFriends: 'दोस्तों के साथ आमंत्रण पोस्टर या लिंक साझा करें',
    FriendsDownloadTheAPPThroughPostersOrLinks: 'दोस्त पोस्टर या लिंक के माध्यम से ऐप डाउनलोड करते हैं',
    FillInYourInvitationPasswordWhenYourFriendRegistersTheAPP: 'जब आपका दोस्त ऐप पर पंजीकरण करता है, तो अपना आमंत्रण पासवर्ड भरें',
    AfterAFriendDownloadsTheAPPAndRegistersWithYourInvitationPasswordTaWillBecomeYourFan: 'जब दोस्त ऐप डाउनलोड करता है और आपके आमंत्रण पासवर्ड का उपयोग करके पंजीकरण करता है, तो वह आपका फैन बन जाएगा',
    saveThePoster: 'विशेष पोस्टर को गैलरी में सहेजें',
    //उपयोगकर्ता फीडबैक
    customerFeedback: 'उपयोगकर्ता फीडबैक',
    selectQuestionType: 'कृपया फीडबैक प्रश्न का प्रकार चुनें',
    massageFeedback: 'घर पर मालिश सेवा फीडबैक',
    APPFeedback: 'ऐप फीडबैक',
    otherFeedback: 'अन्य समस्या फीडबैक',
    YourQuestion: 'आपका प्रश्न (अनिवार्य)',
    ContactInformation: 'संपर्क जानकारी',
    submit: 'सबमिट करें',
    //सेटिंग्स
    setUp: 'सेटअप',
    personalInformation: 'व्यक्तिगत जानकारी',
    changePassword: 'पासवर्ड बदलें',
    UserAgreement: 'उपयोगकर्ता अनुबंध',
    And: 'और',
    PrivacyPolicy: 'गोपनीयता नीति',
    cancelAccount: 'खाता रद्द करें',
    modifyLanguage: 'भाषा बदलें',
    signOut: 'लॉग आउट',
    logInImmediately: 'अभी लॉग इन करें',
    multiLanguage: 'बहुभाषी',
    LanguageSettingSuggestions: 'भाषा सेटिंग पूरी हो गई, हम सुझाव देते हैं कि आप ऐप में फिर से प्रवेश करें',
    hint: 'संकेत',
    OK: 'ठीक है',
    //सेवा समय (स्थानीय समय)
    localTime: '（स्थानीय समय）',
    today: 'आज',
    tomorrow: 'कल',
    Monday: 'सोमवार',
    Tuesday: 'मंगलवार',
    Wednesday: 'बुधवार',
    Thursday: 'गुरुवार',
    friday: 'शुक्रवार',
    Saturday: 'शनिवार',
    sunday: 'रविवार',
    night: 'रात',
    expired: 'समाप्त',
    confirmChoice: 'चुनाव की पुष्टि करें',
    //मेरे पदचिन्ह
    myFootprint: 'मेरे पदचिन्ह',
    //शहर/पता चुनें
    SelectCity: 'शहर चुनें',
    selectAddress: 'पता चुनें',
    nearbyCities: 'नजदीकी शहर',
    SearchCommunityNameOrBuildingName: 'समुदाय नाम/भवन नाम खोजें',
    searchCityName: 'शहर का नाम खोजें',
    popular: 'लोकप्रिय',
    CurrentCity: 'वर्तमान शहर',
    //फिल्टर पॉपअप
    workingStatus: 'काम की स्थिति',
    AvailableByAppointment: 'आरक्षण द्वारा उपलब्ध',
    TechnicianSex: 'तकनीशियन का लिंग',
    sort: 'श्रेणी',
    unlimited: 'बिना सीमा',
    maleTechnician: 'पुरुष तकनीशियन',
    femaleTechnician: 'महिला तकनीशियन',
    technicianAge: 'तकनीशियन की उम्र',
    minimumAge: 'न्यूनतम उम्र',
    maximumAge: 'अधिकतम उम्र',
    technicianHeight: 'तकनीशियन की ऊँचाई',
    minimumHeight: 'न्यूनतम ऊँचाई',
    maximumHeight: 'अधिकतम ऊँचाई',
    MinimumWeight: '최소 중량',
    MaximumWeight: '최대 중량',
    technicianName: 'तकनीशियन का नाम',
    PleaseEnterTheTechnicianName: 'कृपया तकनीशियन का नाम दर्ज करें',
    reset: 'रीसेट',
    Sure: 'निश्चित',
    //लॉगिन/रजिस्ट्रेशन
    Login: 'लॉगिन',
    register: 'रजिस्टर',
    PleaseEnterThePhoneNumber: 'कृपया फोन नंबर दर्ज करें',
    pleaseEnterPassword: 'कृपया पासवर्ड दर्ज करें',
    SignInToAgree: 'लॉगिन करने से सहमति होती है',
    and: 'और',
    VerificationCodeLogin: 'वेरिफिकेशन कोड लॉगिन',
    NewUserRegistration: 'नए उपयोगकर्ता की रजिस्ट्रेशन',
    pleaseEnterVerificationCode: 'कृपया वेरिफिकेशन कोड दर्ज करें',
    getVerificationCode: 'वेरिफिकेशन कोड प्राप्त करें',
    passwordLogin: 'पासवर्ड लॉगिन',
    Reacquire: 'फिर से प्राप्त करें',
    PleaseSetAPasswordOfMoreThanSixCharacters: 'कृपया 6 से अधिक अक्षरों का पासवर्ड सेट करें',
    pleaseEnterPasswordAgain: 'कृपया पासवर्ड फिर से दर्ज करें',
    ConfirmPassword: 'पासवर्ड की पुष्टि करें',
    InvitationCodeIsOptional: 'आमंत्रण कोड (वैकल्पिक)',
    InvitationCodeRequired: 'आमंत्रण कोड, अनिवार्य',
    AlreadyHaveAnAccountToLogin: 'पहले से ही खाता है, लॉगिन करें',
    //नीचे डेटा संकेत
    loadMore: 'और लोड करें',
    noMoreData: 'कोई और डेटा नहीं है',
    loadingMoreData: 'अधिक डेटा लोड हो रहा है',
    //ऑर्डर विवरण
    orderDetails: 'ऑर्डर विवरण',
    Finish: 'पूरा',
    PendingPayment: 'भुगतान की प्रतीक्षा',
    tobeDelivered: 'डिलीवरी की प्रतीक्षा',
    TobeReceived: 'प्राप्ति की प्रतीक्षा',
    WeChatPayment: 'वीचैट भुगतान',
    toPay: 'भुगतान करने के लिए',
    DeliveryMethod: 'डिलीवरी विधि',
    TheAmountOfGoods: 'सामान की राशि',
    MembershipVoucherDeduction: 'सदस्यता वाउचर कटौती',
    promoAmountTitle: 'पहले से छूट',
    deliveryFee: 'डिलीवरी शुल्क',
    payAmountTitle: 'मूल मूल्य',
    reserveAmountTitle: 'पहले से भुगतान की गई राशि',
    reserveAmountDescTitle: 'अग्रिम राशि विवरण',
    OrderList: 'ऑर्डर सूची',
    Receipt: 'प्राप्ति की पुष्टि',
    AccountBalanceDeduction: 'खाते की शेष राशि कटौती',
    //लॉगिन/रजिस्ट्रेशन पॉपअप संकेत
    ToastPleaseEnterThePhoneNumber: 'कृपया फोन नंबर दर्ज करें',
    ToastValidNumber: 'कृपया वैध फोन नंबर दर्ज करें',
    ToastPleaseEnterPassword: 'कृपया पासवर्ड दर्ज करें',
    AtLeastSix: 'पासवर्ड की लंबाई कम से कम 6 वर्ण होनी चाहिए',
    AtLeastFive: 'वेरिफिकेशन कोड की लंबाई कम से कम 5 वर्ण होनी चाहिए',
    ToastAuthorizedLogin: 'कृपया उपयोगकर्ता और गोपनीयता नीति पर सहमति दें',
    ToastAuthorizedRegister: 'कृपया उपयोगकर्ता और गोपनीयता नीति पर सहमति दें, रजिस्टर करने के लिए जारी रखें',
    ToastLoginFailed: 'लॉगिन विफल',
    ToastSendCode: 'वेरिफिकेशन कोड भेजा गया',
    ToastCodeFailed: 'वेरिफिकेशन कोड प्राप्त करने में विफल',
    ToastPleaseEnterVerificationCode: 'कृपया वेरिफिकेशन कोड दर्ज करें',
    ToastLoading: 'लोड हो रहा है...',
    ToastPasswordsDoNotMatch: 'पासवर्ड मेल नहीं खाते',
    ToastPleaseEnterTheInvitationCode: 'कृपया आमंत्रण कोड दर्ज करें (वैकल्पिक)',
    ToastRegistrationFailed: 'रजिस्ट्रेशन विफल',
    CheckYourNetworkConnection: 'रजिस्ट्रेशन विफल, कृपया नेटवर्क कनेक्शन की जांच करें',
    RegistrationMeansConsent: 'रजिस्ट्रेशन का अर्थ सहमति है',
    ToastCopySuccessfully: 'कॉपी सफल',
    loginSuccess: 'लॉगिन सफल',
    loginFailed: 'लॉगिन विफल, कृपया बाद में प्रयास करें',
    passwordWrong: 'पासवर्ड गलत',
    userNotFound: 'उपयोगकर्ता नहीं मिला',
    NotFoundRegister: 'उपयोगकर्ता नहीं मिला, कृपया पहले रजिस्टर करें',
    maxLimitReached: 'हर दिन अधिकतम 5 सूचनाएँ प्राप्त कर सकते हैं',
    reached: 'वेरिफिकेशन कोड भेजने में विफल, दैनिक सीमा तक पहुँच गई',
    FailedSendCode: 'वेरिफिकेशन कोड भेजने में विफल, कृपया बाद में फिर से प्रयास करें',
    FalseCode: 'वेरिफिकेशन कोड गलत, कृपया फिर से भेजें',
    //कोई डेटा नहीं पृष्ठभूमि संकेत
    noOrderYet: 'अभी तक कोई ऑर्डर नहीं है',
    SelectService: 'आइए पसंदीदा सेवा चुनें',
    LoginToViewYourOrder: 'अपने ऑर्डर को देखने के लिए लॉगिन करें',
    //देश चुनने का पॉपअप
    Chinese: 'चीन मुख्य भूमि',
    HongKong: 'हांगकांग',
    Thailand: 'थाईलैंड',
    MacaoChina: 'मकाऊ, चीन',
    TaiwanChina: 'ताइवान, चीन',
    USA: 'संयुक्त राज्य अमेरिका',
    OtherCountries: 'अन्य देश',
    ChooseACountry: 'देश चुनें',
    orderCount: 'सेवा किए गए ऑर्डर',
    Partner: 'साझेदार',
    testFirebase: 'testFirebase',
    malaysia: 'मलेशिया',
    singapore: 'सिंगापुर',
    ThaiBank: 'थाई बैंक कार्ड (जमा करने के बाद ग्राहक सेवा से भुगतान कोड प्राप्त करें)',
    OrderTaxiFee: 'टैक्सी शुल्क',
    OrderCashTotal: 'कुल नकद भुगतान',
    ETA: 'अनुमानित आगमन समय',
    PlsSelectTime: 'कृपया समय चुनें',
    Success: 'सफलता',
    workerName: 'तकनीशियन',
    otherPayment: 'अन्य भुगतान विधियाँ (ग्राहक सेवा से संपर्क करें)',
    otherPaymentInfo: 'Touch N Go आदि का समर्थन करता है',
    AppStoreReview: 'AppStore समीक्षा',
    bwh: 'आकार',
    serviceReservationTime: 'आरक्षण समय',
    noNeedKeyCard: 'लिफ्ट के लिए कार्ड की आवश्यकता नहीं है',
    goToRoomDirectly: 'फ्रंट डेस्क से सीधे कमरे में जाएं',
    waitingAtLobby: 'लॉबी में प्रतीक्षा',
    waitingAtLift: 'लिफ्ट के पास प्रतीक्षा',
    gotIt: 'मुझे समझ में आया',
    depositDesc: 'अग्रिम राशि भुगतान विवरण',
    bookingIndexTitle: 'होटल में घर पर मालिश के लिए बुकिंग, बैंकॉक, पटाया, चियांग माई, फुकेत',
};
