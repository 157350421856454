<template>
  <div class="worker-info">
    <TopBack />
    <div class="content">
      <ul class="worker-list" v-if="workers.length">
        <li v-for="worker in workers" :key="worker.id" class="worker-item">
          <img :src="worker.avatarUrl" alt="头像" class="avatar" />
          <div class="worker-info">
            <h2 class="worker-name">{{ worker.name }}</h2>
            <p class="worker-gender">性别: {{ worker.gender === 1 ? '男' : '女' }}</p>
            <p class="worker-status">工作状态: {{ worker.workStatusDesc }}</p>
            <p class="worker-location">所在城市: {{ worker.province }}</p>
            <p class="worker-appointment">预约时间: {{ worker.recentDate }}</p>
          </div>
        </li>
      </ul>
      <p v-else>没有工作人员可供显示。</p>
    </div>
  </div>
</template>

<script>
import { workerList } from "@/api/worker";
import TopBack from "@/components/common/navigation/TopBack.vue";

export default {
  name: "SwitchTechnician",
  components: { TopBack },
  data() {
    return {
      productId: null,
      workers: [],
      lat: null,
      lng: null,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.getRouterData();
    this.fetchWorkers();
  },
  methods: {
    getRouterData() {
      this.productId = this.$route.query.productId
      console.log('productId', this.productId)
      this.lat = this.$route.query.latitude || 0
      this.lng = this.$route.query.longitude || 0
      console.log(this.latitude, this.longitude)
    },
    fetchWorkers() {
      workerList({
        proxyId: process.env.VUE_APP_proxyId || 7050280,
        pageNum: 1,
        pageSize: 10,
        lat: this.lat,
        lng: this.lng,
        productId: this.productId,
      })
          .then(response => {
            this.workers = response.data.result.records || []; // 确保获取到的记录
          })
          .catch(error => {
            console.error('请求出错:', error);
          });
    },

  },
};
</script>

<style scoped>
button {
  padding: 2px 5px;
}
.worker-list {
  list-style: none;
  padding: 0;
}
.worker-item {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  margin: 10px 0;
  padding: 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}
.worker-info {
  flex: 1;
}
.worker-name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.worker-gender,
.worker-status,
.worker-location,
.worker-appointment {
  margin: 5px 0;
  color: #666;
}
</style>
