import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import * as VueGoogleMaps from 'vue2-google-maps'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
import {createPinia, PiniaVuePlugin} from "pinia";
import VueCountryIntl from 'vue-country-intl';
import 'vue-country-intl/lib/vue-country-intl.css'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import {router} from "@/config/router";
import en from '@/locale/en';
import tzh from "@/locale/tzh";            // 导入English语言
import ko from "@/locale/ko";
import zh from "@/locale/zh";
import ja from '@/locale/ja';
import ar from '@/locale/ar';
import hi from './locale/hi';

Vue.use(Vuetify)

Vue.component(VueCountryIntl.name, VueCountryIntl);

Vue.use(vueMiniPlayer)

Vue.config.productionTip = false
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAaMrKJM7_6h-KgS37hoNcjR_0Uk5qluU0', // 此处填入谷歌地图申请的key
        libraries: 'places'
    }
})
Vue.use(VueRouter);

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
window.pinia = pinia

new Vue({
    router,
    vuetify: new Vuetify({
        lang: {
            locales: {zh, en,tzh,ko,ja,ar,hi},   // 所有的语言
            current: (() => {
                // 1. Check localStorage for userLocale
                const storedLang = localStorage.getItem('userLocale');

                // 2. If no localStorage language, check system language
                const systemLang = navigator.language || navigator.userLanguage;

                // 3. Define available locales
                const availableLocales = ['zh', 'en', 'tzh', 'ko', 'ja', 'ar', 'hi'];

                // 4. Set current language with priority: localStorage -> system language -> 'zh'
                let lang = storedLang || (availableLocales.includes(systemLang) ? systemLang : 'zh');
                localStorage.setItem('userLocale',lang)
                return lang;
            })(),
        }
    }),
    pinia,
    render: h => h(App)
}).$mount('#app');

