<template>
  <div class="worker-comment">
    <TopBar :title="`技师评价`" :leftIcon="'arrow_back'"/>

    <v-list style="margin-top: 50px">
      <v-list-item v-if="comments.length === 0" class="no-comment">
        <v-list-item-content class="no-comment-text">
          暂无评论
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-for="comment in comments" :key="comment.id" class="comment-item">
        <!-- 用户头像 -->
        <v-list-item-avatar>
          <v-avatar size="40">
            <v-img
                :src="comment.userAvatarUrl ? comment.userAvatarUrl : 'https://static.vecteezy.com/system/resources/previews/008/302/462/original/eps10-grey-user-icon-or-logo-in-simple-flat-trendy-modern-style-isolated-on-white-background-free-vector.jpg'"
                alt="用户头像"
            ></v-img>
          </v-avatar>
        </v-list-item-avatar>

        <!-- 评论内容 -->
        <v-list-item-content>
          <!-- 评论头部：评分和日期 -->
          <v-list-item-subtitle class="comment-header">
            <v-rating
                :value="comment.workerScore"
                color="red"
                dense
                half-increments
                readonly
                size="14"
            ></v-rating>
            <span class="comment-date">{{ formatDate(comment.created) }}</span>
          </v-list-item-subtitle>

          <!-- 评论正文 -->
          <v-list-item-title class="comment-content">{{ comment.workerContent }}</v-list-item-title>

          <!-- 技师信息 -->
          <v-list-item-subtitle class="worker-info">
            <v-avatar size="20">
              <v-img :src="comment.workerAvatarUrl" alt="技师头像"></v-img>
            </v-avatar>
            <span>{{ comment.workerName }} {{ comment.workerPosition }}</span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

    </v-list>
  </div>
</template>


<script>
import TopBar from "@/components/common/navigation/TopBar.vue";
import {getWorkerCommentList} from "@/api/worker";

export default {
  name: "WorkerComment",
  components: {TopBar},
  data() {
    return {
      workerId: null,
      comments: []
    }
  },
  created() {
    this.workerId = this.$route.query.workerId
  },
  mounted() {
    this.getWorkerCommentList()
    window.scroll(0, 0)
  },
  methods: {
    getWorkerCommentList() {
      getWorkerCommentList(0, 20, this.workerId).then(res => {
        this.comments = res.data.result.records
        console.log('getWorkerCommentList: ', this.comments)
      })
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    getInitials(name) {
      return name ? name.charAt(0).toUpperCase() : '?';
    }
  }
}
</script>

<style scoped>
.worker-comment {
  background-color: #f5f5f5;
}

.comment-item {
  margin-bottom: 8px;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.comment-date {
  font-size: 12px;
  color: #999;
}

.comment-content {
  font-size: 14px;
  margin-bottom: 4px;
}

.worker-info {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}

.worker-info .v-avatar {
  margin-right: 4px;
}

.v-rating .v-icon {
  padding: 0;
}

.no-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: #999;
  font-size: 16px;
}
</style>

