<template>
  <div class="merchant-info">
    <TopBack/>
    <h1>{{ merchant.shopName }}</h1>
    <div class="button-group">
      <v-btn @click="showProducts = true">项目列表</v-btn>
      <v-btn @click="showProducts = false">工作人员</v-btn>
    </div>
    <div class="content">
      <div v-if="showProducts">
        <div class="product-list">
          <div v-if="productList.length > 0">
            <div v-for="product in productList" :key="product.id" class="product"
                 @click="getServiceHtmlContent(product.id, product.name, product.price, images[0])">
              <div class="product-details">
                <img :src="product.imageUrl" alt="产品图片" width="110" height="110"/>
                <div class="product-info">
                  <div class="product-header">
                    <div class="product-name">{{ product.name }}</div>
                    <div class="product-meta">
                      {{ product.serviceTime }}{{ $vuetify.lang.t("$vuetify.minute") }}
                      <v-chip color="red" outlined small>
                        {{ product.recentDate }} {{ product.recentFreeTime }}
                      </v-chip>
                    </div>
                  </div>
                  <div class="text-body-1">
                    {{ $vuetify.lang.t("$vuetify.sold") }}{{ product.soldCount }}{{ $vuetify.lang.t("$vuetify.doorToService") }}
                  </div>
                  <div class="text-body-1">{{ product.descContent }}</div>
                  <div class="product-footer">
                    <div class="text-h6 red--text text--darken-1">฿{{ product.price }}/ {{ $vuetify.lang.t("$vuetify.SecondRate") }}</div>
                    <v-chip @click.stop="ProjectAppointment(product.id)" color="red" text-color="white">{{ $vuetify.lang.t("$vuetify.ReservationNow") }}</v-chip>
                  </div>
                </div>
              </div>
              <v-divider></v-divider>
            </div>
          </div>
          <div v-else>
            <p>没有可用的项目。</p>
          </div>
        </div>
      </div>
      <div v-else>
        <ul class="worker-list">
          <li v-for="worker in workers" :key="worker.id" class="worker-item"
          @click = "goToAppointment(worker.id)">
            <img :src="worker.avatarUrl" alt="头像" class="avatar" />
            <div class="worker-info">
              <h2 class="worker-name">{{ worker.name }}</h2>
              <p class="worker-gender">性别: {{ worker.gender === 1 ? '男' : '女' }}</p>
              <p class="worker-status">工作状态: {{ worker.workStatusDesc }}</p>
              <p class="worker-location">所在城市: {{ worker.province }}</p>
              <p class="worker-appointment">预约时间: {{ worker.recentDate }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getMerchant } from '@/api/merchant';
import { getMerchantProductListTest } from '@/api/merchant';
import {workerList} from "@/api/worker";
import { useStore } from "@/config/store";
import TopBack from "@/components/common/navigation/TopBack.vue";
export default {
  name: "MerchantPageTest",
  components: {TopBack},
  setup() {
    const store = useStore();
    return {
      isUserLoggedIn: store.isUserLoggedIn, // 直接引用 getter
    };
  },
  data() {
    console.log('this.$route.query.latitude:' + this.$route.query.latitude)
    console.log('this.$route.query.longitude:' + this.$route.query.longitude)
    return {
      merchant: {
        shopName: '',
      },
      workers: [],
      productList: [],
      showProducts: true,
      images: [],
      lat: null,
      lng:  null,
      index: 1,
      selectedMerchantId: this.$route.query.merchantId || null
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  created() {

    this.getRouterData();
    this.fetchMerchantData();
    this.fetchProductData();
  },

  methods: {
    getRouterData() {
      console.log('workerId', this.workerId);
      this.lat = this.$route.query.latitude || 0;
      this.lng = this.$route.query.longitude || 0;
      console.log(this.latitude, this.longitude);
      // 获取经纬度后调用 fetchWorkers
      this.fetchWorkers(this.lat, this.lng, this.selectedMerchantId);
    },
    fetchWorkers() {
      workerList({
        proxyId: process.env.VUE_APP_proxyId || 7050280,
        pageNum: 1,
        pageSize: 10,
        lat: this.lat,
        lng: this.lng,
        selectedMerchantId: this.selectedMerchantId,
      })
          .then(response => {
            // 直接将 workers 更新为返回的记录，或者用一个默认值
            this.workers = response.data.result.records || [];
          })
          .catch(error => {
            console.error('请求出错:', error);
          });
    },

    fetchMerchantData() {
      getMerchant(this.selectedMerchantId).then(data => {
        this.merchant = data.data.result;
      }).catch(error => {
        console.error('获取商家数据出错:', error);
      });
    },

    fetchProductData() {
      getMerchantProductListTest(this.selectedMerchantId).then(res => {
        this.productList = res.data.result;
        console.log("getMerchantProductList: ", this.productList);
      }).catch(error => {
        console.error('获取产品数据出错:', error);
      });
    },

    getServiceHtmlContent(productId, productName, productPrice, avatar, name, workerId) {
      this.$router.push({
        name: 'htmlContent',
        query: {
         latitude: this.lat,
         longitude: this.lng,
         productId: productId,
         productName: productName,
         productPrice: productPrice,
         avatar: avatar,
         name: name,
         workerId: workerId
        }
      });
    },

    ProjectAppointment() {
      // 检查用户是否登录
      if (!this.isUserLoggedIn) {
        // 如果未登录，跳转到登录页面
        alert('请登录后再使用');
        this.$router.push({ name: 'LoginPage' });
      } else {
        // 如果已登录，继续跳转到项目预约页面
        this.$router.push({
          name: 'ProjectAppointment',
          query: {
            productId: this.productId,
            workerId: this.workerId,
            workerName: this.workerDto?.name || '',
            avatar: this.workerDto?.avatarUrl || ''
          }
        });
      }
    },
    toWorkerComment() {
      this.$router.push({
        name: 'WorkerComment',
        query: {
          workerId: this.workerId,
        }
      });
    },
    goToAppointment(workerId) {
      console.log('openGallery')
      this.$router.push({
        name: 'Detail',
        query: {
          id: workerId,
          latitude: this.lat,
          longitude: this.lng
        }
      })
    },
  }
}
</script>
<style scoped>
.product {
  margin-top: 5px;
}
button {
  padding: 2px 5px 2px 5px;
}
.worker-list {
  list-style: none;
  padding: 0;
}
.worker-item {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  margin: 10px 0;
  padding: 15px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}
.worker-info {
  flex: 1;
}
.worker-name {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.worker-gender,
.worker-status,
.worker-location,
.worker-appointment {
  margin: 5px 0;
  color: #666;
}
</style>
