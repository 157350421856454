import ar from 'vuetify/es5/locale/ar';  // استيراد حزمة اللغة العربية من Vuetify
export default {
    label: 'العربية',
    book: 'حجز',
    orders: 'الطلبات',
    me: 'ملفي',
    ok: 'حسناً',
    cancel: 'إلغاء',
    //تسجيل الدخول
    SelectAreaACode: 'اختيار رمز المنطقة الحالية',
    PleaseEnterMobilePhoneNumber: 'يرجى إدخال رقم الهاتف المحمول',
    PleaseEnterPassword: 'يرجى إدخال كلمة المرور',
    Password: 'كلمة المرور',
    PhoneNumber: 'رقم الهاتف المحمول',
    VerificationCode: 'رمز التحقق',
    secondsLater: "ثواني لإعادة المحاولة",
    androidDownload: 'تنزيل لنظام الأندرويد',
    iosDownload: 'تنزيل لنظام iOS',
    switchLanguage: 'تبديل اللغة',
    english: 'الإنجليزية',
    chinese: 'الصينية',
    changeToEnglish: 'تغيير إلى الإنجليزية',
    changeToChinese: 'تغيير إلى الصينية',
    changeToSystem: 'تغيير إلى لغة النظام',
    //صفحتي
    commonVip: 'عضو عادي',
    FollowTechnician: 'متابعة الفني',
    FollowServices: 'متابعة الخدمات',
    FollowStore: 'متابعة المتجر',
    paid: 'في انتظار الدفع',
    served: 'في انتظار الخدمة',
    accepted: 'في انتظار الاستلام',
    evaluated: 'في انتظار التقييم',
    MyOrder: 'طلباتي',
    friends: 'دعوة الأصدقاء',
    ContactService: 'خدمة العملاء عبر الإنترنت',
    DownloadOptions: 'خيارات التنزيل',
    Aftersales: 'ردود الفعل',
    Myevaluation: 'تقييمي',
    Merchant: 'إدارة التاجر',
    TechnicianOrders: 'توظيف الفنيين',
    BrowseHistory: 'سجل التصفح',
    ViewAll: 'عرض الكل',
    //قائمة الحجز المباشر
    TechnicianList: 'الفنيين للحجز المباشر',
    all: 'الكل',
    allList: 'الكل',
    currentLocation: 'الموقع الحالي',
    latitude: 'خط العرض',
    longitude: 'خط الطول',
    RefreshLocation: 'تحديث الموقع',
    SearchLocation: 'البحث عن الموقع',
    doorToDoorFast: 'خدمة سريعة',
    HighPopularity: 'شعبية عالية',
    praise: 'تقييمات إيجابية',
    latestAddition: 'أحدث الإضافات',
    followMore: 'المزيد من المتابعات',
    filter: 'تصفية',
    Comment: 'تقييم',
    strip: 'عدد',
    NoStaffNearby: 'لا يوجد موظفين قريبين',
    //طلبي
    orderNumber: 'رقم الطلب',
    totalOrder: 'الدفع عبر الإنترنت',
    orderTime: 'وقت الطلب',
    //تفاصيل الفني
    Verified: 'تحقق من الهوية',
    age: 'العمر',
    focusOn: 'تركيز',
    Followed: 'تم المتابعة',
    Zodiac: 'البرج',
    constellation: 'النجمة',
    nationality: 'الجنسية',
    education: 'التعليم',
    height: 'الطول',
    distance: 'المسافة',
    weight: 'الوزن',
    language: 'اللغة',
    Introduction: 'المقدمة',
    businessLicense: 'رخصة العمل',
    healthCertificate: 'شهادة صحية',
    masseurCertificate: 'شهادة مساج',
    Check: 'عرض',
    serviceItems: 'عناصر الخدمة',
    goodEvaluate: 'تقييم جيد',
    commentAmount: 'عدد التقييمات',
    minute: 'دقيقة',
    sold: 'تم البيع',
    SecondRate: 'مرة',
    ReservationNow: 'احجز الآن',
    doorToService: 'خدمة منزلية',
    photoAlbum: 'ألبوم الصور',
    video: 'فيديو',
    //حالة الفني
    available: 'متاح للحجز',
    inService: 'في الخدمة',
    resting: 'في استراحة',
    //إجراء الطلب
    PlaceAnOrder: 'إجراء الطلب',
    serviceAddress: 'عنوان الخدمة',
    hotel: 'فندق/شقة',
    houseNumber: 'رقم الغرفة/البناية',
    Name: 'الاسم',
    Telephone: 'الهاتف',
    serviceHours: 'ساعات الخدمة',
    servicePersonnel: 'أفراد الخدمة',
    PromoCode: 'رمز الخصم',
    Inquire: 'استفسار',
    paymentMethod: 'طريقة الدفع',
    DepositPaid: 'الدفعة المقدمة المعلقة',
    bookAndPay: 'احجز وادفع',
    hotelPlaceholder: 'يرجى إدخال اسم الفندق أو الشقة الخاصة بك',
    houseNumberPlaceholder: 'عنوان مفصل، مثل، الغرفة 101، المبنى A',
    pinyinSpelling: 'التهجئة بالصينية',
    gentlemen: 'سيدي',
    Miss: 'سيدة',
    PleaseSelectTime: 'يرجى اختيار وقت الخدمة (بتوقيت المحلي)',
    canComeEarly: 'يمكن الوصول مبكراً',
    callBeforeComing: 'يرجى الاتصال قبل القدوم',
    PleaseContactMeAsSoonAsPossible: 'يرجى الاتصال بي في أقرب وقت ممكن',
    businessMessage: 'أرسل رسالة للبائع',
    PleaseEnterDiscountCode: 'يرجى إدخال رمز الخصم (اختياري)',
    Alipay: 'دفع عبر Alipay',
    PayPal: 'بطاقة ائتمان MasterCard و VISA / PayPal',
    paymentSuccessful: 'تم الدفع بنجاح',
    paymentFailed: 'فشل الدفع',
//متابعاتي / تقييماتي
    myFocus: 'متابعاتي',
    myComment: 'تقييمي',
    technician: 'الفني',
    Serve: 'الخدمة',
    shop: 'المتجر',
    delete: 'حذف',
    Revise: 'تعديل',
    details: 'تفاصيل',
//خدمة العملاء المتعددة
//دعوة التسجيل
    inviteRegister: 'دعوة للتسجيل',
    invitationPassword: 'كلمة مرور الدعوة',
    copy: 'نسخ',
    invitationSteps: 'خطوات الدعوة',
    ShareInvitationPostersOrInvitationLinksToFriends: 'شارك ملصقات الدعوة أو روابط الدعوة مع الأصدقاء',
    FriendsDownloadTheAPPThroughPostersOrLinks: 'الأصدقاء يقومون بتنزيل التطبيق من خلال الملصقات أو الروابط',
    FillInYourInvitationPasswordWhenYourFriendRegistersTheAPP: 'قم بإدخال كلمة مرور الدعوة الخاصة بك عند تسجيل صديقك في التطبيق',
    AfterAFriendDownloadsTheAPPAndRegistersWithYourInvitationPasswordTaWillBecomeYourFan: 'بعد أن يقوم الصديق بتنزيل التطبيق وتسجيل الدخول باستخدام كلمة مرور الدعوة الخاصة بك، سيصبح معجبك',
    saveThePoster: 'احفظ الملصق الخاص بك في المعرض',
//ملاحظات المستخدم
    customerFeedback: 'ملاحظات العملاء',
    selectQuestionType: 'يرجى اختيار نوع مشكلة الملاحظات',
    massageFeedback: 'ملاحظات خدمة المساج',
    APPFeedback: 'ملاحظات التطبيق',
    otherFeedback: 'ملاحظات أخرى',
    YourQuestion: 'سؤالك (مطلوب)',
    ContactInformation: 'معلومات الاتصال',
    submit: 'تقديم',
//الإعدادات
    setUp: 'إعدادات',
    personalInformation: 'المعلومات الشخصية',
    changePassword: 'تغيير كلمة المرور',
    UserAgreement: 'اتفاقية المستخدم',
    And: 'و',
    PrivacyPolicy: 'سياسة الخصوصية',
    cancelAccount: 'إلغاء الحساب',
    modifyLanguage: 'تغيير اللغة',
    signOut: 'تسجيل الخروج',
    logInImmediately: 'تسجيل الدخول الآن',
    multiLanguage: 'متعدد اللغات',
    LanguageSettingSuggestions: 'تم إعداد اللغة، يُفضل إعادة الدخول إلى التطبيق',
    hint: 'تلميح',
    OK: 'حسناً',
//ساعات الخدمة (بتوقيت المحلي)
    localTime: ' (بتوقيت المحلي)',
    today: 'اليوم',
    tomorrow: 'غداً',
    Monday: 'الاثنين',
    Tuesday: 'الثلاثاء',
    Wednesday: 'الأربعاء',
    Thursday: 'الخميس',
    friday: 'الجمعة',
    Saturday: 'السبت',
    sunday: 'الأحد',
    night: 'ليلاً',
    expired: 'مكتمل',
    confirmChoice: 'تأكيد الاختيار',
//آثاري
    myFootprint: 'آثاري',
//اختيار المدينة/العنوان
    SelectCity: 'اختر مدينة',
    selectAddress: 'اختر عنوان',
    nearbyCities: 'مدن قريبة',
    SearchCommunityNameOrBuildingName: 'ابحث عن اسم المجتمع أو اسم المبنى',
    searchCityName: 'ابحث عن اسم المدينة',
    popular: 'شائع',
    CurrentCity: 'المدينة الحالية',
    //نافذة تصفية
    workingStatus: 'حالة العمل',
    AvailableByAppointment: 'متاح للحجز',
    TechnicianSex: 'جنس الفني',
    sort: 'تصنيف',
    unlimited: 'غير محدد',
    maleTechnician: 'فني ذكر',
    femaleTechnician: 'فنية أنثى',
    technicianAge: 'عمر الفني',
    minimumAge: 'الحد الأدنى للعمر',
    maximumAge: 'الحد الأقصى للعمر',
    technicianHeight: 'طول الفني',
    minimumHeight: 'الحد الأدنى للطول',
    maximumHeight: 'الحد الأقصى للطول',
    minimumWeight: 'الوزن الأدنى',
    maximumWeight: 'الوزن الأقصى',
    technicianName: 'اسم الفني',
    PleaseEnterTheTechnicianName: 'يرجى إدخال اسم الفني',
    reset: 'إعادة تعيين',
    Sure: 'تأكيد',
//تسجيل الدخول/التسجيل
    Login: 'تسجيل الدخول',
    register: 'تسجيل',
    PleaseEnterThePhoneNumber: 'يرجى إدخال رقم الهاتف',
    pleaseEnterPassword: 'يرجى إدخال كلمة المرور',
    SignInToAgree: 'تسجيل الدخول يعني الموافقة',
    and: 'و',
    VerificationCodeLogin: 'تسجيل الدخول باستخدام رمز التحقق',
    NewUserRegistration: 'تسجيل مستخدم جديد',
    pleaseEnterVerificationCode: 'يرجى إدخال رمز التحقق',
    getVerificationCode: 'الحصول على رمز التحقق',
    passwordLogin: 'تسجيل الدخول باستخدام كلمة المرور',
    Reacquire: 'إعادة الحصول',
    PleaseSetAPasswordOfMoreThanSixCharacters: 'يرجى تعيين كلمة مرور تتكون من أكثر من 6 أحرف',
    pleaseEnterPasswordAgain: 'يرجى إدخال كلمة المرور مرة أخرى',
    ConfirmPassword: 'تأكيد كلمة المرور',
    InvitationCodeIsOptional: 'رمز الدعوة (اختياري)',
    InvitationCodeRequired: 'رمز الدعوة، مطلوب',
    AlreadyHaveAnAccountToLogin: 'لديك حساب؟ قم بتسجيل الدخول',
//نصائح أسفل الصفحة
    loadMore: 'تحميل المزيد',
    noMoreData: 'لا توجد بيانات أخرى',
    loadingMoreData: 'يتم تحميل المزيد من البيانات',
//تفاصيل الطلب
    orderDetails: 'تفاصيل الطلب',
    Finish: 'إكمال',
    PendingPayment: 'في انتظار الدفع',
    tobeDelivered: 'في انتظار التسليم',
    TobeReceived: 'في انتظار الاستلام',
    WeChatPayment: 'دفع عبر WeChat',
    toPay: 'اذهب للدفع',
    DeliveryMethod: 'طريقة التوصيل',
    TheAmountOfGoods: 'المبلغ المستحق',
    MembershipVoucherDeduction: 'خصم قسيمة العضوية',
    promoAmountTitle: 'تم الخصم',
    deliveryFee: 'رسوم التوصيل',
    payAmountTitle: 'المبلغ الأصلي',
    reserveAmountTitle: 'تم دفع الدفعة المقدمة',
    reserveAmountDescTitle: 'وصف الدفعة المقدمة',
    OrderList: 'قائمة الطلبات',
    Receipt: 'تأكيد الاستلام',
    AccountBalanceDeduction: 'خصم من رصيد الحساب',
//نصائح نافذة تسجيل الدخول/التسجيل
    ToastPleaseEnterThePhoneNumber: 'يرجى إدخال رقم الهاتف',
    ToastValidNumber: 'يرجى إدخال رقم هاتف صحيح',
    ToastPleaseEnterPassword: 'يرجى إدخال كلمة المرور',
    AtLeastSix: 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل',
    AtLeastFive: 'يجب أن يتكون رمز التحقق من 5 أحرف على الأقل',
    ToastAuthorizedLogin: 'يرجى الموافقة على اتفاقية المستخدم والخصوصية لتسجيل الدخول',
    ToastAuthorizedRegister: 'يرجى الموافقة على اتفاقية المستخدم والخصوصية، واستمر في التسجيل',
    ToastLoginFailed: 'فشل تسجيل الدخول',
    ToastSendCode: 'تم إرسال رمز التحقق',
    ToastCodeFailed: 'فشل في الحصول على رمز التحقق',
    ToastPleaseEnterVerificationCode: 'يرجى إدخال رمز التحقق',
    ToastLoading: 'جار التحميل...',
    ToastPasswordsDoNotMatch: 'كلمات المرور غير متطابقة',
    ToastPleaseEnterTheInvitationCode: 'يرجى إدخال رمز الدعوة (اختياري)',
    ToastRegistrationFailed: 'فشل التسجيل',
    CheckYourNetworkConnection: 'فشل التسجيل، يرجى التحقق من الاتصال بالإنترنت',
    RegistrationMeansConsent: 'التسجيل يعني الموافقة',
    ToastCopySuccessfully: 'تم النسخ بنجاح',
    loginSuccess: 'تسجيل الدخول ناجح',
    loginFailed: 'فشل تسجيل الدخول، يرجى المحاولة لاحقاً',
    passwordWrong: 'كلمة المرور خاطئة',
    userNotFound: 'المستخدم غير موجود',
    NotFoundRegister: 'المستخدم غير موجود، يرجى التسجيل أولاً',
    maxLimitReached: 'يمكنك الحصول على 5 معلومات فقط يومياً',
    reached: 'فشل إرسال رمز التحقق، تم الوصول إلى الحد الأقصى اليومي',
    FailedSendCode: 'فشل إرسال رمز التحقق، يرجى المحاولة لاحقاً',
    FalseCode: 'رمز التحقق خاطئ، يرجى إعادة إرساله',
//نصائح لا توجد بيانات
    noOrderYet: 'لا توجد لديك طلبات بعد',
    SelectService: 'اذهب لاختيار الخدمة التي تفضلها',
    LoginToViewYourOrder: 'يمكنك عرض طلبك بعد تسجيل الدخول',
//نافذة اختيار الدولة
    Chinese: 'الصين البر الرئيسي',
    HongKong: 'هونغ كونغ',
    Thailand: 'تايلاند',
    MacaoChina: 'ماكاو',
    TaiwanChina: 'تايوان',
    USA: 'الولايات المتحدة الأمريكية',
    OtherCountries: 'دول أخرى',
    ChooseACountry: 'اختر دولة',
    orderCount: 'طلبات تم خدمتها',
    Partner: 'شريك',
    testFirebase: 'testFirebase',
    malaysia: 'ماليزيا',
    singapore: 'سنغافورة',
    ThaiBank: 'بطاقة بنك تايلاند (يرجى تقديمها إلى خدمة العملاء للحصول على رمز الدفع)',
    OrderTaxiFee: 'أجرة التاكسي',
    OrderCashTotal: 'إجمالي المبلغ المستحق',
    ETA: 'الوقت المقدر للوصول',
    PlsSelectTime: 'يرجى اختيار الوقت',
    Success: 'نجاح',
    workerName: 'الفني',
    otherPayment: 'طرق دفع أخرى (يرجى الاتصال بخدمة العملاء بعد التقديم)',
    otherPaymentInfo: 'يدعم Touch N Go وغيرها',
    AppStoreReview: 'تقييم في AppStore',
    bwh: 'الأبعاد',
    serviceReservationTime: 'وقت الحجز',
    noNeedKeyCard: 'لا حاجة لبطاقة الدخول للمصعد',
    goToRoomDirectly: 'يتعين على الاستقبال مساعدتك إلى الغرفة',
    waitingAtLobby: 'انتظار في اللوبي',
    waitingAtLift: 'انتظار عند المصعد',
    gotIt: 'فهمت',
    depositDesc: 'وصف الدفع المسبق',
    bookingIndexTitle: 'احجز خدمة المساج في الفندق، بانكوك، باتايا، شيانغ ماي، فوكيت',
    ...ar
};
