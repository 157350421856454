<template>
  <div>
    <BottomNavigation />
    <CustomerService ref="customerService" />
    <DownloadService ref="downloadService" />
    <LanguageSetting ref="languageSetting" />
    <div class="top">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="setting">
            <v-icon color="white">settings</v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item @click="openLanguageDialog">
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.modifyLanguage") }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item @click="logout" v-if="store.idtoken">
            <v-list-item-title>{{ $vuetify.lang.t("$vuetify.signOut") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div class="top-info">
        <v-avatar color="primary" size="70">
          <img v-if="store.idtoken" src="https://static.vecteezy.com/system/resources/previews/008/302/462/original/eps10-grey-user-icon-or-logo-in-simple-flat-trendy-modern-style-isolated-on-white-background-free-vector.jpg" alt="">
          <img v-else src="https://icons.veryicon.com/png/o/miscellaneous/two-color-icon-library/user-286.png" alt="">
        </v-avatar>
        <div class="text-h5" style="color: white; margin: auto 0">
          <div v-if="!store.idtoken">
            <strong @click="ToLoginPage">{{ $vuetify.lang.t("$vuetify.Login") }}</strong>｜
            <strong @click="ToRegisterPage">{{ $vuetify.lang.t("$vuetify.register") }}</strong>
          </div>
          <strong v-else>{{ store.phone }}</strong>
        </div>
      </div>
    </div>
    <div class="button-container">
      <v-card class="card">
        <div class="item">
          <v-btn @click="showCustomerService" icon>
            <img width="45" :src="require('@/assets/pinkCustomerService@2x.png')" alt="在线客服">
          </v-btn>
          <div class="description">{{ $vuetify.lang.t("$vuetify.ContactService") }}</div> <!-- 描述信息 -->
        </div>
        <div class="item">
          <v-btn @click="showDownloadService" icon>
            <img width="45" :src="require('@/assets/pinkCustomerService@2x.png')" alt="下载">
          </v-btn>
          <div class="description">{{ $vuetify.lang.t("$vuetify.DownloadOptions") }}</div> <!-- 描述信息 -->
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import BottomNavigation from "@/components/common/navigation/BottomNavigation.vue";
import { useStore } from "@/config/store";
import CustomerService from "@/components/common/CustomerService.vue";
import LanguageSetting from "@/components/profile/LanguageSetting.vue";
import DownloadService from "@/components/common/DownloadService.vue";

export default {
  name: "ProfilePage",
  components: { LanguageSetting, CustomerService, BottomNavigation, DownloadService },
  computed: {
    store() {
      return useStore();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    ToLoginPage() {
      this.$router.push({ name: 'LoginPage' });
    },
    ToRegisterPage() {
      this.$router.push({ name: 'RegisterPage' });
    },
    logout() {
      localStorage.removeItem('store');
      this.store.logout();
      window.location.reload();
    },
    showCustomerService() {
      this.$refs.customerService.dialog = true;
    },
    showDownloadService() {
      this.$refs.downloadService.dialog = true;
    },
    openLanguageDialog() {
      this.$refs.languageSetting.dialog = true;
    }
  }
}
</script>

<style scoped>
.top {
  width: 100%;
  background: linear-gradient(to right, #e14f47, #e66a5a);
}

.setting {
  margin: 30px 10px 0 0;
  float: right;
}

.top-info {
  margin: 50px 20px 30px 20px;
  display: flex;
  gap: 30px;
  flex-direction: row;
}

.button-container {
  display: flex;
  justify-content: flex-start; /* 左对齐 */
  margin: 160px 10px 0 10px; /* 外边距 */
}

.card {
  display: flex;
  justify-content: flex-start; /* 按钮左对齐 */
  padding: 10px; /* 增加内边距 */
  width: 100%; /* 100%宽度 */
  max-width: none; /* 不设置最大宽度 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
  border-radius: 8px; /* 圆角 */
}

.item {
  display: flex; /* 水平排列 */
  flex-direction: column; /* 让按钮和描述垂直排列 */
  align-items: center; /* 按钮和描述居中对齐 */
  margin-right: 20px; /* 按钮之间的右边距 */
}

.description {
  margin-top: 5px; /* 描述与按钮之间的间距 */
  color: black; /* 修改为黑色以便可见 */
  font-size: 14px; /* 描述文本大小 */
}
</style>
