import axios from 'axios';
import {useStore} from "@/config/store";

const instance = axios.create({});

const defaultHeaders = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "language": localStorage.getItem('userLocale') || 'zh',
    "Accept-Language": localStorage.getItem('userLocale') || 'zh'
};
const createRequestConfig = (customHeaders = {}, useToken = false) => {
    const headers = {...defaultHeaders, ...customHeaders};
    if (useToken) {
        headers["X-Access-Token"] = useStore().jwt;
    }
    return {headers};
};

const request = (method, url, data = null, useToken = false) => {
    const config = createRequestConfig({}, useToken);
    return method === 'get' ? instance.get(url, config) : instance.post(url, data, config);
};

export const getMerchant = (merchantId) =>
    request('get', `/aappii/wealth/merchant/getMerchant?merchantId=` + merchantId);

export const getMerchantProductListTest = (merchantId) =>
    request('get', `/aappii/wealth/product/getMerchantProductListTest?merchantId=` + merchantId);