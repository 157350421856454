<template>
  <div class="order-now">
    <LoadingIndicator :value="loading"/>
    <AddressList :model-value="LocationDialog"
                 @selectLocation="changeSelectLocation"
                 @closeDialog="LocationDialog = false"/>
    <SelectServiceTime :model-value="ServiceTimeDialog"
                       @selectServiceTime="changeServiceTime"
                       @closeDialog="ServiceTimeDialog = false"
                       :worker-id="this.workerId"/>
    <v-snackbar style="z-index: 1001" :color="color" text v-model="snackbar">
      <strong class="text-subtitle-1">{{ text }}</strong>
    </v-snackbar>

    <TopBar :title="`下单 - 产品 ID: ${productId}`" :leftIcon="'arrow_back'"/>
    <div class="map">
      <GmapMap :center="{lat: this.lat, lng: this.lng}" :zoom="10" style="width:100%;height: 270px"></GmapMap>
    </div>
    <div class="form-info">
      <v-card class="inner-card" outlined>
        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                    item-disabled=""
                    outlined
                    dense
                    :placeholder="this.receiverDetailAddress"
                    v-model="this.receiverDetailAddress"
                    :rules="addressRules"
                    hint="请选择"
                    hide-details="auto"
                    class="equal-height"
                    disabled-lookup
                    @click="openLocationDialog"
                    prepend-inner-icon="location_on">
                </v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                    outlined
                    dense
                    v-model="hotelName"
                    :rules="hotelRules"
                    hint="输入您所在公寓或酒店的名称"
                    label="酒店/公寓"
                    class="equal-height"
                    hide-details="auto"
                    prepend-inner-icon="bed"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                    outlined
                    dense
                    v-model="roomNumber"
                    :rules="roomRules"
                    hint="详细地址，例如，A座101室"
                    label="房间/门牌号"
                    class="equal-height"
                    hide-details="auto"
                    prepend-inner-icon="meeting_room"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-text-field
                    outlined
                    dense
                    v-model="name"
                    :rules="nameRules"
                    hint="拼音全拼"
                    label="姓名"
                    class="equal-height"
                    hide-details="auto"
                    prepend-inner-icon="badge"
                ></v-text-field>
              </v-col>

              <v-col cols="6" md="4">
                <v-radio-group
                    v-model="gender"
                    row style="margin-top: 0;height: 40px"
                >
                  <v-radio
                      label="先生"
                      value="1"
                  ></v-radio>
                  <v-radio
                      label="女士"
                      value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                    outlined
                    dense
                    v-model="phoneNumber"
                    :rules="phoneRules"
                    hint="电话号码"
                    label="电话"
                    class="equal-height"
                    hide-details="auto"
                    prepend-inner-icon="call"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>

      <v-card class="inner-card" outlined>
        <v-col cols="12" md="4">
          <v-select
              outlined dense
              hide-details="auto"
              class="equal-height"
              prepend-inner-icon="schedule"
              item-disabled=""
              :placeholder="this.receiverServiceTime === '' ? '请选择' : dayjs(this.receiverServiceTime).format('YYYY-MM-DD HH:mm:ss')"
              v-model="this.receiverServiceTime"
              hint="请选择"
              disabled-lookup
              @click="openServiceTimeDialog"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <div style="display: flex;justify-content: space-between;padding: 0 4px;">
            <div class="text-subtitle-1" style="margin: auto 0">服务人员</div>
            <div class="text-subtitle-2">
              <v-avatar size="40"><img :src="this.avatar" alt=""></v-avatar>
              {{ this.workerName }}
            </div>
          </div>
        </v-col>
      </v-card>

      <v-card class="inner-card" outlined>
        <div class="product-info" style="">
          <img width="70" style="border-radius: 5px" :src="this.orderInfo.picUrl" alt="">
          <div style="display: flex;flex-direction: column;justify-content: space-between">
            <div class="text-h6">{{ this.orderInfo.productName }}</div>
            <div class="text-body-2">
              <span>{{ this.orderInfo.price }}/次</span> &nbsp;
              <span><v-icon small>schedule</v-icon>{{ this.orderInfo.serviceTime }}min</span>
            </div>
          </div>
        </div>
      </v-card>


      <v-card class="inner-card" outlined>
        <v-col cols="12" md="4">
          <div>
            <div>
              <v-chip color="primary" outlined small>电梯不需要刷卡</v-chip>&nbsp;
              <v-chip color="primary" outlined small>前台帮忙到房间</v-chip>&nbsp;
              <v-chip color="primary" outlined small>大厅等候</v-chip>&nbsp;
              <v-chip outlined small>电梯口等候</v-chip>
            </div>
            <div style="margin-top: 10px">
              <v-textarea
                  outlined
                  name="input-7-4"
                  label="给商家捎句话"
                  value=""
              ></v-textarea>
            </div>
          </div>
        </v-col>
      </v-card>

      <v-card class="inner-card" outlined>
        <v-col cols="12" md="4" style="display: flex;flex-direction: row;justify-content: space-between;gap: 15px">
          <v-text-field
              v-model="promoCode"
              label="优惠码"
              hint="请输入优惠码（选填）"
              persistent-hint
              dense
              outlined
              prepend-inner-icon="card_giftcard"
          ></v-text-field>
          <v-btn color="primary" style="margin-top: 2px">查询</v-btn>
        </v-col>
      </v-card>

      <v-card class="inner-card" outlined>
        <v-col cols="12" md="4">
          <div class="text-subtitle-1">支付方式</div>
          <v-divider/>
          <div class="text-subtitle-2">
            <v-radio-group v-model="selectedPaymentMethod" column>
              <div v-for="(method, index) in payMethods" :key="index"
                   style="display: flex; flex-direction: row; align-items: center;">
                <v-radio :label="method.name" :value="method.value"></v-radio>
                <img class="pay-logo" :src="method.img" width="24" height="24" :alt="method.name">
              </div>
            </v-radio-group>
          </div>
        </v-col>
      </v-card>

      <v-card class="inner-card" outlined>
        <v-col cols="12" md="4">
          <div class="text-subtitle-1" @click="toggleMoreInfo"> {{ showMoreInfo ? '隐藏更多设置' : '显示更多设置' }}</div>
          <v-divider/>
          <div class="text-subtitle-2">
            <div v-if="showMoreInfo">
              <!-- 代人下单复选框 -->
              <label>
                <input type="checkbox" v-model="isAgentOrder" />
                代人下单
              </label>
            </div>
          </div>
        </v-col>
      </v-card>
    </div>
    <div style="height: 100px"></div>
    <BottomBar>
      <template v-slot:left>
        ฿ {{ orderInfo.price }}
      </template>
      <template v-slot:right>
        待支付定金：฿ {{ orderInfo.reserveAmount }}
        <v-btn @click="submit" color="error" depressed>
          预约并支付
        </v-btn>
      </template>
    </BottomBar>

  </div>
</template>

<script>
import TopBar from "@/components/common/navigation/TopBar.vue";
import BottomBar from "@/components/common/navigation/BottomBar.vue";
import {
  buyNowConfirmOrder,
  getAddrByLocation,
  getUserPhone, orderGeneration,
  PaymentInfo2,
} from "@/api/worker";
import LoadingIndicator from "@/components/common/LoadingIndicator.vue";
import AddressList from "@/components/common/map/AddressList.vue";
import SelectServiceTime from "@/components/booking/SelectServiceTime.vue";
import dayjs from "dayjs";
import {useStore} from "@/config/store";

export default {
  name: "ProjectAppointment",
  computed: {
    dayjs() {
      return dayjs
    }
  },
  components: {SelectServiceTime, AddressList, LoadingIndicator, BottomBar, TopBar},
  data() {
    return {
      workerName: '',
      avatar: '',
      productId: null,
      workerId: null,
      loading: true,
      lat: 0,
      lng: 0,
      receiverDetailAddress: '',
      hotelName: '',
      roomNumber: '',
      name: '',
      gender: 0,
      phoneNumber: '',
      receiverServiceTime: '',
      LocationDialog: false,
      ServiceTimeDialog: false,
      payMethods: [],
      orderInfo: {},
      calcAmountInfo: 0,
      payMoney: 0,
      deliveryMethod: '',
      quantity: 1,
      promoCode: '',
      selectedPaymentMethod: null,
      addressId: 0,
      snackbar: false,
      text: '',
      color: 'primary',
      showMoreInfo: false,   // 控制更多设置的显示和隐藏
      isAgentOrder: false,   // 控制是否为代人下单
    }
  },
  created() {
    this.productId = this.$route.query.productId;
    this.workerId = this.$route.query.workerId
    this.workerName = this.$route.query.workerName
    this.avatar = this.$route.query.avatar
  },
  mounted() {
    if (!useStore().isLogined) {
      alert('请登录后再试')
      this.loading = false
      return
    }
    this.getUserPhone()
    this.getPayInfo();
    this.getCurrent()
    window.scroll(0, 0)
    this.gender = 1
  },
  methods: {
    toggleMoreInfo() {
      this.showMoreInfo = !this.showMoreInfo;
    },
    getCurrent() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            position => {
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;
              console.log("lat: ", this.lat, "lng: ", this.lng)
              this.getAddrByLocation()
              this.getOrderInfo();//预生成
            },
            error => {
              console.error("获取位置失败:", error);
              alert("获取位置失败 请手动选择")
              this.loading = false
            }
        );
      } else {
        console.error("浏览器不支持地理位置功能。");
        alert("获取位置失败 请手动选择")
      }
    },

    getAddrByLocation() {
      getAddrByLocation(this.lat, this.lng).then(res => {
        console.log("getAddrByLocation: ", res)
        this.receiverDetailAddress = res.data.result.formatted_address;
        console.log("receiverDetailAddress: ", this.receiverDetailAddress)
      })
    },

    buyNowConfirmOrder() {
      const data = {
        productAttr: "",
        productId: this.productId,
        quantity: 1,
        deductionType: 0,
        receiverDetailAddress: this.receiverDetailAddress,
        receiverLat: this.lat,
        receiverLng: this.lng,
        promoCode: ""
      }
      buyNowConfirmOrder(data).then(res => {
        console.log("buyNowConfirmOrder: ", res)
        this.loading = false
      })
    },
    openLocationDialog(event) {
      event.stopPropagation();  // 阻止事件传播
      this.$nextTick(() => {
        this.LocationDialog = true;
      });
    },
    openServiceTimeDialog(event) {
      event.stopPropagation();
      this.$nextTick(() => {
        this.ServiceTimeDialog = true;
      })
    },
    changeSelectLocation(location) {
      console.log("changeSelectLocation: ", location)
      this.receiverDetailAddress = location.formatted_address
      this.lat = location.lat
      this.lng = location.lng
    },
    changeServiceTime(time) {
      this.receiverServiceTime = time
      console.log("changeServiceTime: ", this.receiverServiceTime)
    },
    getUserPhone() {
      getUserPhone().then(res => {
        this.phoneNumber = res.data.result
        console.log('getUserPhone: ', res)
      })
    },
    getPayInfo() {
      PaymentInfo2(6).then((response) => {
        console.log('支付PaymentInfo:', response)
        let res = response.data
        let itemList = [];
        if (res.code === 200 && res.result) {
          // if (res.result.isEnableAlipayAPP) {
          //   itemList.push({name: 'Alipay', img: require('../../assets/pay/orderImg02.png'), value: 1});
          // }
          // if (res.result.isEnableWechatAPP2) {
          //   itemList.push({name: '微信支付', img: require('../../assets/pay/orderImg03.png'), value: 2});
          // }
          if (res.result.isEnableAlipayWap) {
            itemList.push({name: 'Alipay', img: require('../../assets/pay/orderImg02.png'), value: 4});
          }
          // if (res.result.isAdapayForWechatAPP) {
          //   itemList.push({name: '微信支付', img: require('../../assets/pay/orderImg03.png'), value: 6});
          // }
          if (res.result.isPayPal) {
            itemList.push({name: 'PayPal', img: require('../../assets/pay/PayPal.png'), value: 8});
          }
          if (res.result.isThaiBank) {
            itemList.push({name: 'ThaiBank', img: require('../../assets/pay/thailand.png'), value: 10});
          }
          itemList.push({name: 'otherPayment', img: require('../../assets/pay/dollar.png'), value: 55});
          if (itemList.length > 0) {
            this.payMethods = itemList;
          }
        }
      }).catch(error => {
        console.error("获取支付信息失败:", error);
      });
    },

    // 获取订单信息
    async getOrderInfo() {
      this.loading = true;
      try {
        const res = await orderGeneration(
            this.productId,
            this.quantity,
            0,
            this.receiverDetailAddress,
            this.lat,
            this.lng,
            this.promoCode
        );
        console.log('orderGeneration: ', res)
        if (res.code === 200) {
          this.orderInfo = res.result.item;
        }
      } catch (error) {
        console.error('获取订单信息失败:', error);
      } finally {
        this.loading = false;
      }
    },

    // 表单验证
    validateForm() {
      if (!this.receiverDetailAddress) return '服务地址为空';
      if (!this.hotelName) return '请输入酒店或者公寓名称';
      if (!this.roomNumber) return '请输入门牌号';
      if (!this.name) return '请输入姓名';
      if (!this.gender) return '请选择您的性别';
      if (!this.phoneNumber) return '请输入您的联系方式';
      if (!this.receiverServiceTime) return '请选择服务时间（当地时间）';
      if (!this.selectedPaymentMethod) return '请选择支付方式';
      return null;
    },

    async submit() {
      const validationError = this.validateForm();
      if (validationError) {
        console.log(validationError)
        this.showSnackbar(validationError, 'error');
        return;
      }

      this.loading = true;

      const orderData = {
        addressId: this.addressId,
        cardDeductionAmount: this.orderInfo.calcAmount?.cardDeductionAmount || 0,
        deductionType: 0,
        merchantId: this.orderInfo.merchantId,
        orderType: 2,
        payType: this.selectedPaymentMethod,
        productId: this.productId,
        quantity: this.quantity,
        receiverDetailAddress: `${this.receiverDetailAddress} ${this.roomNumber}`,
        receiverLat: this.lat,
        receiverLng: this.lng,
        shopBalanceDeductionAmount: this.orderInfo.calcAmount?.shopBalanceDeductionAmount || 0,
        workerId: this.workerId,
        serviceReservationTime: this.receiverServiceTime,
        place: this.hotelName,
        houseNumber: this.roomNumber,
        contactPhone: this.phoneNumber,
        gender: this.gender,
        name: this.name,
        remark: '',
        promoCode: this.promoCode,
        isAgentOrder: this.isAgentOrder
      };

      console.log('orderData: ', orderData)

      try {
        let res = null
        switch (this.selectedPaymentMethod) {
          case 4: // 支付宝
            res = await buyNowConfirmOrder(orderData);
            await this.handleAlipayPayment(res.data.result);
            break;
          case 8: // PayPal
            orderData.wayCode = 'PP_PC'
            res = await buyNowConfirmOrder(orderData);
            await this.handlePayPalPayment(res.data.result);
            break;
          case 10: // ThaiBank
            orderData.wayCode = ''
            res = await buyNowConfirmOrder(orderData);
            await this.handleThaiBankPayment(res.data);
            break
          case 55: // 其他支付方式
            res = await buyNowConfirmOrder(orderData);
            await this.handleOtherPayment(res.data);
            break;
          default:
            throw new Error('未知的支付方式');
        }
      } catch (error) {
        console.error('支付处理出错:', error);
        this.showSnackbar(`支付失败: ${error.message}`, 'error');
      } finally {
        this.loading = false;
      }
    },

    async handleAlipayPayment(paymentInfo) {
      console.log('handleAlipayPayment: ', paymentInfo)
      if (paymentInfo && paymentInfo.aliPayHtml) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = paymentInfo.aliPayHtml;
        document.body.appendChild(tempDiv);
        tempDiv.querySelector('form').submit();
      } else {
        console.error('支付信息无效或缺失', paymentInfo);
      }
    },

    async handleWechatPayment(paymentInfo) {
      // 实现微信支付逻辑
      console.log('处理微信支付', paymentInfo);
    },

    async handlePayPalPayment(paymentInfo) {
      // 实现 PayPal 支付逻辑
      console.log('处理 PayPal 支付', paymentInfo);
      window.open(paymentInfo.payPalUrl)
    },

    async handleThaiBankPayment(paymentInfo) {
      console.log('handleThaiBankPayment: ', paymentInfo)
      if (paymentInfo.success) {
        await this.$router.push({name: 'OrderDetail', params: {orderId: paymentInfo.result.orderId}});
      }

    },

    async handleOtherPayment(paymentInfo) {
      // 实现其他支付逻辑
      console.log('处理其他支付方式', paymentInfo);
      if (paymentInfo.success) {
        await this.$router.push({name: 'OrderDetail', params: {orderId: paymentInfo.result.orderId}});
      }
    },

    showSnackbar(text, color) {
      if (color) {
        this.color = color
      }
      this.text = text
      this.snackbar = true
    }
  }
}
</script>

<style scoped>
.form-info {
  margin: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-now {
  background: #efefef;
}

.inner-card {
  padding: 0 5px;
}

.product-info {
  display: flex;
  gap: 10px;
  padding: 7px 5px;
}

.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  color: #ffffff;
  background: #1976D2;
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.pay-logo {
  margin-left: 5px;
  transform: translateY(-3px);
}
</style>
