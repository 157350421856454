// src/locale/zh.js
import zhHant from 'vuetify/es5/locale/zh-Hant';   // 导入Vuetify 自带的简体中文语言包
export default {
    label: '繁體中文',
    book: '預約',
    orders: '訂單',
    me: '我',
    ok: '確定',
    cancel: '取消',
    switchLanguage: '切換語言',
    SelectAreaACode: '選擇區域代碼當前',
    PleaseEnterMobilePhoneNumber: '請輸入手機號碼',
    PleaseEnterPassword: '請輸入密碼',
    Password: '密碼',
    PhoneNumber: '手機號碼',
    VerificationCode: '驗證碼',
    secondsLater: "秒後",
    //下載界面
    androidDownload: '安卓下載',
    iosDownload: 'iOS下載',
    english: '英文',
    chinese: '中文',
    changeToEnglish: '切換到英文',
    changeToChinese: '切換到中文',
    changeToSystem: '切換到系統',
    //我的頁面
    commonVip: '會員',
    FollowTechnician: '關注技術人員',
    FollowServices: '關注服務',
    FollowStore: '關注店鋪',
    paid: '已支付',
    served: '服務中',
    accepted: '已完成',
    evaluated: '已評價',
    MyOrder: '我的訂單',
    friends: '邀請朋友',
    ContactService: '聯繫客服',
    DownloadOptions: '下載選項',
    Aftersales: '售後服務',
    Myevaluation: '我的評價',
    Merchant: '商家後台',
    TechnicianOrders: '技術人員申請',
    BrowseHistory: '瀏覽歷史',
    ViewAll: '查看全部',
    //直約列表
    TechnicianList: '預約',
    all: '全部',
    allList: '所有',
    currentLocation: '當前位置',
    latitude: '緯度',
    longitude: '經度',
    RefreshLocation: '刷新位置',
    SearchLocation: '搜索位置',
    doorToDoorFast: '上門速度',
    HighPopularity: '高人氣',
    praise: '好評',
    filter: '篩選',
    latestAddition: '最新加入',
    followMore: '關注更多',
    Comment: '評論',
    strip: '條',
    NoStaffNearby: '附近沒有技術人員',
    //我的訂單
    orderNumber: '訂單編號',
    totalOrder: '訂單總額',
    orderTime: '訂單時間',
    //技師詳情
    Verified: '已驗證',
    age: '年齡',
    focusOn: '關注',
    Followed: '已關注',
    Zodiac: '生肖',
    constellation: '星座',
    nationality: '國籍',
    education: '教育程度',
    height: '身高',
    distance: '距離',
    weight: '體重',
    language: '語言',
    Introduction: '介紹',
    businessLicense: '營業執照',
    healthCertificate: '健康證明',
    masseurCertificate: '按摩師證書',
    Check: '查看',
    serviceItems: '服務項目',
    goodEvaluate: '好評率',
    commentAmount: '評論數',
    minute: '分鐘',
    sold: '已售出',
    SecondRate: '人數',
    ReservationNow: '立即預約',
    doorToService: '上門服務',
    photoAlbum: '相冊',
    video: '視頻',
    //技師狀態
    available: '可用',
    inService: '服務中',
    resting: '休息中',
    //下單
    PlaceAnOrder: '下單',
    serviceAddress: '服務地址',
    hotel: '酒店/公寓',
    houseNumber: '房間號',
    Name: '姓名',
    Telephone: '手機',
    serviceHours: '服務時間',
    servicePersonnel: '服務人員',
    PromoCode: '優惠碼',
    Inquire: '查詢',
    paymentMethod: '支付方式',
    DepositPaid: '支付定金',
    bookAndPay: '預約並支付',
    hotelPlaceholder: '請輸入酒店或公寓名稱',
    houseNumberPlaceholder: '詳細地址，例如A座101室',
    pinyinSpelling: '姓名拼音',
    gentlemen: '先生',
    Miss: '小姐',
    PleaseSelectTime: '請選擇時間（當地時間）',
    canComeEarly: '可以提前到',
    callBeforeComing: '到達前打電話',
    PleaseContactMeAsSoonAsPossible: '請儘快聯繫我',
    businessMessage: '給商家留言',
    PleaseEnterDiscountCode: '請輸入優惠碼（可選）',
    Alipay: '支付寶',
    PayPal: 'PayPal/信用卡',
    paymentSuccessful: '支付成功',
    paymentFailed: '支付失敗',
    //我的關注/評價
    myFocus: '我的關注',
    myComment: '我的評論',
    technician: '技術人員',
    Serve: '服務',
    shop: '店鋪',
    delete: '刪除',
    Revise: '修改',
    details: '詳情',
    //多客服
    //邀請註冊
    inviteRegister: '邀請註冊',
    invitationPassword: '邀請碼',
    copy: '複製',
    invitationSteps: '邀請步驟',
    ShareInvitationPostersOrInvitationLinksToFriends: '將邀請海報或邀請鏈接分享給朋友',
    FriendsDownloadTheAPPThroughPostersOrLinks: '朋友通過海報或鏈接下載APP',
    FillInYourInvitationPasswordWhenYourFriendRegistersTheAPP: '朋友註冊時填寫邀請碼',
    AfterAFriendDownloadsTheAPPAndRegistersWithYourInvitationPasswordTaWillBecomeYourFan: '朋友註冊成功後將成為您的粉絲',
    saveThePoster: '保存海報',
    //用戶反饋
    customerFeedback: '客戶反饋',
    selectQuestionType: '請選擇反饋問題類型',
    massageFeedback: '按摩反饋',
    APPFeedback: 'APP反饋',
    otherFeedback: '其他反饋',
    YourQuestion: '您的問題（必填）',
    ContactInformation: '聯繫方式',
    submit: '提交',
    //設置
    setUp: '設置',
    personalInformation: '個人信息',
    changePassword: '修改密碼',
    UserAgreement: '用戶協議',
    And: '和',
    PrivacyPolicy: '隱私政策',
    cancelAccount: '註銷帳戶',
    modifyLanguage: '語言設置',
    signOut: '登出',
    logInImmediately: '立即登錄',
    multiLanguage: '多語言',
    LanguageSettingSuggestions: '語言設置完成，建議重新進入App',
    hint: '提示',
    OK: '確認',
    //服務時間（當地時間）
    localTime: '（當地時間）',
    today: '今天',
    tomorrow: '明天',
    Monday: '星期一',
    Tuesday: '星期二',
    Wednesday: '星期三',
    Thursday: '星期四',
    friday: '星期五',
    Saturday: '星期六',
    sunday: '星期天',
    night: '晚上',
    expired: '已過期',
    confirmChoice: '確認選擇',
    //我的足跡
    myFootprint: '我的足跡',
    //選擇城市/地址
    SelectCity: '選擇城市',
    selectAddress: '選擇地址',
    nearbyCities: '附近城市',
    SearchCommunityNameOrBuildingName: '搜索小區或樓宇名稱',
    searchCityName: '搜索城市名稱',
    popular: '熱門',
    CurrentCity: '當前城市',
    //篩選彈窗
    workingStatus: '工作狀態',
    AvailableByAppointment: '可預約',
    TechnicianSex: '性別',
    sort: '排序',
    unlimited: '不限',
    maleTechnician: '男性技術人員',
    femaleTechnician: '女性技術人員',
    technicianAge: '年齡',
    minimumAge: '最小年齡',
    maximumAge: '最大年齡',
    technicianHeight: '技術人員身高',
    minimumHeight: '最小身高',
    maximumHeight: '最大身高',
    minimumWeight: '最大重量',
    maximumWeight: '最小重量',
    technicianName: '技術人員姓名',
    PleaseEnterTheTechnicianName: '請輸入技術人員姓名',
    reset: '重置',
    Sure: '確認',
    //登錄/註冊
    Login: '登錄',
    register: '註冊',
    PleaseEnterThePhoneNumber: '請輸入手機號碼',
    pleaseEnterPassword: '請輸入密碼',
    SignInToAgree: '登錄即表示同意',
    and: '和',
    VerificationCodeLogin: '驗證碼登錄',

    NewUserRegistration: '新用戶註冊',
    pleaseEnterVerificationCode: '請輸入驗證碼',
    getVerificationCode: '獲取驗證碼',
    passwordLogin: '密碼登入',
    Reacquire: '重新獲取',
    PleaseSetAPasswordOfMoreThanSixCharacters: '請設置6位以上密碼',
    pleaseEnterPasswordAgain: '請再次輸入密碼',
    ConfirmPassword: '確認密碼',
    InvitationCodeIsOptional: '邀請碼（選填）',
    InvitationCodeRequired: '邀請碼，必填',
    AlreadyHaveAnAccountToLogin: '已有帳號登入',
    //底部数据提示
    loadMore: '加載更多',
    noMoreData: '沒有更多數據了',
    loadingMoreData: '正在加載更多數據',
    //订单详情
    orderDetails: '訂單詳情',
    Finish: '完成',
    PendingPayment: '待付款',
    tobeDelivered: '待發貨',
    TobeReceived: '待收貨',
    WeChatPayment: '微信支付',
    toPay: '去支付',
    DeliveryMethod: '配送方式',
    TheAmountOfGoods: '項目應付金額',
    MembershipVoucherDeduction: '會員券抵扣',
    promoAmountTitle: '已優惠',
    deliveryFee: '配送費',
    payAmountTitle: '原價金額',
    reserveAmountTitle: '已支付訂金',
    reserveAmountDescTitle: '訂金說明',
    OrderList: '訂單列表',
    Receipt: '確認收貨',
    AccountBalanceDeduction: '賬戶餘額抵扣',
    //登录/注册弹窗提示
    ToastPleaseEnterThePhoneNumber: '請輸入手機號碼',
    ToastValidNumber: '請輸入有效的手機號碼',
    ToastPleaseEnterPassword: '請輸入密碼',
    AtLeastSix: '密碼長度至少為6個字符',
    AtLeastFive: '驗證碼長度至少為5個字符',
    ToastAuthorizedLogin: '請您同意用戶和隱私協議後登入',
    ToastAuthorizedRegister: '請您同意用戶和隱私協議，繼續註冊',
    ToastLoginFailed: '登入失敗',
    ToastSendCode: '驗證碼已發送',
    ToastCodeFailed: '驗證碼獲取失敗',
    ToastPleaseEnterVerificationCode: '請輸入驗證碼',
    ToastLoading: '加載中...',
    ToastPasswordsDoNotMatch: '密碼不一致',
    ToastPleaseEnterTheInvitationCode: '請輸入邀請碼（選填）',
    ToastRegistrationFailed: '註冊失敗',
    CheckYourNetworkConnection: '註冊失敗，請檢查網絡連接',
    RegistrationMeansConsent: '註冊即代表同意',
    ToastCopySuccessfully: '複製成功',
    loginSuccess: '登入成功',
    loginFailed: '登入失敗，請稍後再試',
    passwordWrong: '密碼錯誤',
    userNotFound: '用戶不存在',
    NotFoundRegister: '用戶不存在，請先註冊',
    maxLimitReached: '每天最多獲取5條信息',
    reached: '發送驗證碼失敗，每日次數已達上限',
    FailedSendCode: '驗證碼發送失敗，請稍後重試',
    FalseCode: '驗證碼錯誤，請重新發送',
    //暂无数据背景提示
    noOrderYet: '還沒有訂單哦',
    SelectService: '快去挑選心儀的服務吧',
    LoginToViewYourOrder: '登入後可查看您的訂單',
    //选择国家弹窗
    Chinese: '中國大陸',
    HongKong: '中國香港',
    Thailand: '泰國',
    MacaoChina: '中國澳門',
    TaiwanChina: '中國台灣',
    USA: '美國USA',
    OtherCountries: '其他國家',
    ChooseACountry: '選擇國家',
    orderCount: '已服務訂單',
    Partner: '合作夥伴',
    testFirebase: 'testFirebase',
    malaysia: '馬來西亞',
    singapore: '新加坡',
    ThaiBank: '泰國銀行卡（提交後和客服要付款碼）',
    OrderTaxiFee: '車費',
    OrderCashTotal: '應付現金合計',
    ETA: '預估到達時間',
    PlsSelectTime: '請選擇時間',
    Success: '成功',
    workerName: '技師',
    otherPayment: '其他支付方式（提交後聯繫客服）',
    otherPaymentInfo: '支持Touch N Go等',
    AppStoreReview: 'AppStore打分',
    bwh: '三圍',
    serviceReservationTime: '預約時間',
    noNeedKeyCard: '電梯不需要刷卡',
    goToRoomDirectly: '前台幫忙到房間',
    waitingAtLobby: '大廳等候',
    waitingAtLift: '電梯口等候',
    gotIt: '我知道了',
    depositDesc: '支付訂金說明',
    bookingIndexTitle: '預約上門按摩到酒店 曼谷 芭提雅 清邁 普吉島',
    ...zhHant,
}
