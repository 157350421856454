<template>
  <v-dialog
      style="z-index: 2000"
      v-model="dialog"
      persistent
      max-width="400px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $vuetify.lang.t("$vuetify.DownloadOptions") }}</span>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
              v-for="(item, index) in buttons"
              :key="index"
              @click="openInNewTab(item.linkUrl)"
          >
            <v-list-item-avatar>
              <img :src="item.iconUrl" :alt="item.title" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index < buttons.length - 1" />
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
        >
          {{ $vuetify.lang.t("$vuetify.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      // 定义两个按钮的内容和链接
      buttons: [
        {
          title: this.$vuetify.lang.t("$vuetify.androidDownload"),
          iconUrl: "https://pic.616pic.com/ys_b_img/00/04/13/rqAtbdiXeP.jpg",
          linkUrl: "https://pic.616pic.com",
        },
        {
          title: this.$vuetify.lang.t("$vuetify.iosDownload"),
          iconUrl: "https://pic.616pic.com/ys_bnew_img/00/07/78/mZrQvfUm3r.jpg",
          linkUrl: "https://pic.616pic.com",
        }
      ],
    };
  },
  methods: {
    openInNewTab(url) {
      window.open(url, "_blank"); // 在新标签页中打开链接
    },
  },
};
</script>

<style scoped>
</style>
